import { CmTeaserImpl } from './teaser'
import type { FeaturedProducts } from '~/lib/client-server/cms/modules/feature-products'
import { type CmFeaturedProductsOverlaySafe } from '~/lib/client-server/cms/sources/coremedia/shared/schemas'
import {
	getLinkedContentSetting,
	getSimpleOverlayPosition,
	type ModuleTextStyle,
	type ModuleView,
	type SimpleOverlayPosition,
} from '~/lib/cms/legacy/helper'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import type { ModuleTheme } from '~/lib/client-server/cms/modules'

export class CmFeaturedProductsImpl extends CmTeaserImpl {
	static hasPositionData(position: CmFeaturedProductsOverlaySafe | undefined): boolean {
		if (!position) return false
		return position.positionX !== undefined && position.positionY !== undefined
	}

	/**
	 * Evaluate a `CmTeasable` object
	 * @param view `mobile` or `desktop`, used to specify the view settings needed
	 * @returns string | undefined ex: `dark-text`
	 */
	public getFeaturedProductsTextStyling(view: ModuleView = 'mobile'): ModuleTextStyle | undefined {
		// featuredProductsOverlay does not have a type when generated. `uaModuleSettings` returns as Maybe<Scalars['JSON']>.
		const featuredProductsOverlay = this.raw.uaModuleSettings?.featuredProductsOverlay
		if (!featuredProductsOverlay?.[view]) return undefined

		const { linkedStyles } = featuredProductsOverlay[view] as CmFeaturedProductsOverlaySafe
		const setting =
			this.raw.uaModuleLinkSettings && this.raw.uaModuleLinkSettings.find((obj) => obj && obj.key === linkedStyles)
		if (!setting) return undefined

		return getLinkedContentSetting(setting) ? (getLinkedContentSetting(setting) as ModuleTextStyle) : undefined
	}

	public getModuleTheme(): ModuleTheme {
		// The response from the featureProductsOverlay has an inverse dark/light configuration as the standard desktop
		// and mobile theme settings. The logic below reflects that change.
		const desktopStyle = this.getFeaturedProductsTextStyling('desktop') === 'light-text' ? 'dark' : null
		const mobileStyle = this.getFeaturedProductsTextStyling('mobile') === 'light-text' ? 'dark' : null

		// Default style
		let theme: ModuleTheme = 'light'

		if (mobileStyle === 'dark' && desktopStyle === 'dark') {
			// Complete dark theme.
			theme = 'dark'
		} else if (desktopStyle && !mobileStyle) {
			// Dark on desktop only.
			theme = 'dark-desktop'
		} else if (!desktopStyle && mobileStyle) {
			// Dark on mobile only.
			theme = 'dark-mobile'
		}

		return theme
	}

	public toUniversal(): FeaturedProducts {
		const mobilePosition = this.getFeaturedProductOverlay('mobile')
		const desktopPosition = this.getFeaturedProductOverlay('desktop')
		return {
			theme: this.getModuleTheme(),
			products: this.raw.related,
			mobilePosition:
				mobilePosition && CmFeaturedProductsImpl.hasPositionData(mobilePosition)
					? getSimpleOverlayPosition(mobilePosition.positionX || 0, mobilePosition.positionY || 0)
					: 'hidden',
			desktopPosition:
				mobilePosition && CmFeaturedProductsImpl.hasPositionData(desktopPosition)
					? getSimpleOverlayPosition(desktopPosition?.positionX || 0, desktopPosition?.positionY || 0)
					: 'hidden',
		}
	}
}

/**
 * Evaluates a `CmTeasable` object to check if it has `related` products in it. This is primarily used for the `FeaturedProducts` component.
 * @param teasable
 * @returns
 */
export function getFeaturedProducts(teasable: CmTeasable): CmTeasable[] | null {
	if (!teasable?.related || teasable.related.length === 0) return null // if there are no featuredProducts - return nothing
	return teasable.related as CmTeasable[]
}

export function pageHasFeaturedProducts(pageContent: PageContentData) {
	const placements = pageContent?.placements ?? []
	for (let i = 0; i < placements.length; i += 1) {
		const items = placements[i].items || placements[i].uaFilteredItems || placements[i].uaMergedItems
		for (let j = 0; j < items.length || 0; j += 1) {
			if (getFeaturedProducts(items[j])) return true
		}
	}
	return false
}

/**
 * Get position placement of `featuredProducts` icon for either 'desktop' or 'mobile' and will return `SimpleOverlayPosition` for selected view.
 * @param teasable
 * @param view `mobile` or `desktop`, used to specify the view settings needed
 * @returns
 */
export function getFeaturedProductOverlayPosition(
	teasable: CmTeasable,
	view: ModuleView = 'mobile',
): SimpleOverlayPosition {
	// featuredProductsOverlay does not have a type when generated. `uaModuleSettings` returns as Maybe<Scalars['JSON']>.
	const featuredProductsOverlay = teasable?.uaModuleSettings?.featuredProductsOverlay
	// check if there is data for the `featuredProductOverlay` and if so, make sure it is enabled for our view
	if (!featuredProductsOverlay || !featuredProductsOverlay?.[view]) return 'hidden'

	const { enabled, positionX, positionY } = featuredProductsOverlay[view]
	if (!enabled) return 'hidden'
	return getSimpleOverlayPosition(positionX, positionY)
}

/**
 * Evaluate a `CmTeasable` object to see if featured products exist and are shown or not. This is used to disable image linking in modules.
 * @param teasable
 * @returns
 */
export function hasFeaturedProductShown(teasable: CmTeasable) {
	const hasFeaturedProducts = getFeaturedProducts(teasable)
	if (!hasFeaturedProducts) return false

	const isShownOnMobile = getFeaturedProductOverlayPosition(teasable)
	const isShownOnDesktop = getFeaturedProductOverlayPosition(teasable, 'desktop')
	return isShownOnMobile !== 'hidden' || isShownOnDesktop !== 'hidden'
}
