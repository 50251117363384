import { getMobileSettings, getSettingsFromModuleMap, type ModuleView } from './helper'
import { ensureString } from 'types/strict-null-helpers'
import type { CmCollection } from '~/graphql/generated/coremedia/type-document-node'

export type ModuleSlideType = 'ProductTile' | 'CarouselItem'

/**
 * Evaluate a `CmCollection` object to see if there is a mobile carousel or not.
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns true | false
 */
export function hasMobileCarousel(data: CmCollection): boolean {
	const mobileSettings = getMobileSettings(data)
	return mobileSettings ? !!mobileSettings?.mobileCarousel : false
}

/**
 * Evaluate a `CmCollection` object to get the number of items in the mobile carousel, will always be a number
 *
 * Look up the desktopColumnsCount in the module-settings-map for desktop carousel, will always be a number
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns number, always at least 1
 */
export function getCarouselCount(data: CmCollection, view: ModuleView = 'mobile'): number {
	const settings = view === 'mobile' ? getMobileSettings(data) : getSettingsFromModuleMap(ensureString(data.viewtype))
	const mappedSetting = settings && view === 'mobile' ? settings?.mobileColumnsCount : settings?.desktopColumnsCount
	return mappedSetting ?? 1
}

/** Evaluate a `CmCollection` to see if advanced product tiles are used
 *
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns  true | false
 */
export function hasAdvancedProductTiles(data: CmCollection): boolean {
	const { uaModuleSettings } = data
	if (!uaModuleSettings) return false

	return !!uaModuleSettings?.useSFCCProductTile
}
