import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function CaretIcon(restProps: IconProps) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-caret-icon {...getEnhancedIconProps(restProps)}>
			<path
				d="M2 11L7.98939 5.01061C7.99525 5.00475 8.00475 5.00475 8.01061 5.01061L14 11"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default memo(CaretIcon)
