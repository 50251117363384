import type { ReactNode } from 'react'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type { CmTeasable, ExtendedTeaserTarget } from '~/graphql/generated/coremedia/type-document-node'
import { getUrlFromLinkObject } from '~/lib/cms/legacy/helper'

interface LinkProps {
	link?: ExtendedTeaserTarget | null
	children?: ReactNode
	parent: CmTeasable
	placement?: string
	module?: string
	locale: string
}

type CMLinkProps = LinkProps & Partial<React.ComponentPropsWithoutRef<'a'>>

export default function CMLink({ parent, link, children, placement, module, locale, ...otherAttrs }: CMLinkProps) {
	const { analyticsManager } = useAnalytics()
	const href = getUrlFromLinkObject(link?.target, locale) || '#'

	const ctaText = link?.callToActionText

	/**
	 * This look up is a bridge between the updates to UAT that change the graphql schema for CM
	 *
	 * Currently (4/26/23) UAT has a new field `teaserText` that is an object that returns
	 * 		- text (replaces old `teaserText`)
	 * 		- textAsTree (replaces `teaserTextAsTree`)
	 *
	 * `teaserTextAsTree` will stay in as a deprecated option, therefore it will work as a bridge.
	 */
	const headline =
		parent?.teaserTextAsTree?.children?.[0]?.children?.[0]?.data ||
		parent.title ||
		parent.htmlTitle ||
		parent.teaserTitle ||
		''

	function handleContentClick() {
		const linkData = {
			internal_campaign_asset_name: parent.media?.[0]?.name || '',
			internal_campaign_module: module || parent.viewtype || '',
			internal_campaign_link: href,
			internal_campaign_cta_text: ctaText || '',
			internal_campaign_placement: placement || '',
			internal_campaign_snipe: parent.snipeText || '',
			internal_campaign_headline: headline,
		}

		analyticsManager.applyNextPageData(linkData)
	}

	if (link?.target?.type === 'CMExternalLink') {
		return (
			<a href={href} {...otherAttrs}>
				{children ?? ctaText}
			</a>
		)
	}

	return (
		<LocaleLink href={href as string} {...otherAttrs} onClick={handleContentClick}>
			{children ?? ctaText}
		</LocaleLink>
	)
}
