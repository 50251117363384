import type { ReactElement } from 'react'
import { useMemo } from 'react'
import { moduleSettingsMap } from '~/components/cms/legacy/module-settings-map'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { getAthleteTagOverlayPosition, getAthleteTagTextStyling, getAthleteTags } from '~/lib/cms/legacy/athlete-tags'
import logger from '~/lib/logger'
import { ensureString } from '~/types/strict-null-helpers'

import AthleteTag from '~/components/cms/legacy/AthleteTag/AthleteTag'
import FeaturedProducts from '~/components/cms/FeaturedProducts/FeaturedProducts'
import MediaItem from './MediaItem'
import Hotspots from '~/components/shared/Hotspots/Hotspots'
import { CmMediaImpl } from '~/lib/client-server/cms/sources/coremedia/shared/media'
import { CmFeaturedProductsImpl } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'

interface MediaContentProps {
	data: CmTeasable
	className?: string
	mediaPosition?: number
	priority?: boolean
	placement?: string
	module?: string
	imageRecipeMobile?: string
	imageRecipeDesktop?: string
	locale: string
}

const MediaContent = ({
	data,
	className = 'module__primary-img',
	mediaPosition = 0,
	priority,
	placement,
	module,
	imageRecipeDesktop,
	imageRecipeMobile,
	locale,
}: MediaContentProps): ReactElement | null => {
	// TO DO - TEST AND MAKE SURE GETTING THE IMAGE RECIPE AT MEDIA POSITION IS GOOD AND SAFE. THIS WOULD REQUIRE EVERY INITIAL RETURN TO BE AN ARRAY OR TEXT IN TO ARRAY, COULD CAUSE ISSUES BUT HACKY WORKED RIGHT NOW
	const athleteTags = useMemo(() => getAthleteTags(data), [data])
	// const featuredProducts = useMemo(() => getFeaturedProducts(data), [data])

	const featuredProducts = useMemo(() => {
		if (data.related?.length) {
			const featuredProductData = new CmFeaturedProductsImpl(data)
			return featuredProductData.toUniversal()
		}
		return null
	}, [data])

	// getHotspots filters through the data to see if hotspots exist on the UaMedia item. This is done here to see if we need to make the UACAPI calls or not.
	const mobileMediaItem = data?.uaMobileMedia?.[mediaPosition]
	const mobileHotSpots = useMemo(() => {
		if (mobileMediaItem) {
			const media = new CmMediaImpl(mobileMediaItem)
			return media.hotspots
		}
		return null
	}, [mobileMediaItem])

	const desktopMediaItem = data?.uaMedia?.[mediaPosition]
	const desktopHotSpots = useMemo(() => {
		if (desktopMediaItem) {
			const media = new CmMediaImpl(desktopMediaItem)
			return media.hotspots
		}
		return null
	}, [desktopMediaItem])

	const desktopHotspotAnalytics = ensureString(desktopMediaItem?.externalAssetId)
	const mobileHotspotAnalytics = ensureString(mobileMediaItem?.externalAssetId)

	try {
		const { viewtype, uaMobileLayoutSettings } = data
		const desktopImageRecipes = viewtype && moduleSettingsMap[viewtype]?.desktopImageRecipes

		let getImageRecipeDesktop = desktopImageRecipes || ''
		if (typeof getImageRecipeDesktop !== 'string') {
			getImageRecipeDesktop = getImageRecipeDesktop[mediaPosition]
		}

		if (data.media && !data?.media[mediaPosition]) return null

		return (
			<div className={className}>
				<MediaItem
					locale={locale}
					data={data}
					mediaPosition={mediaPosition}
					priority={priority}
					placement={ensureString(placement)}
					module={ensureString(module)}
					imageRecipeDesktop={getImageRecipeDesktop ?? imageRecipeDesktop}
					imageRecipeMobile={uaMobileLayoutSettings?.imageRecipes?.[mediaPosition] ?? imageRecipeMobile}
				>
					{athleteTags && (
						<AthleteTag
							athletes={athleteTags}
							mobilePosition={getAthleteTagOverlayPosition(data, 'mobile')}
							mobileTextStyle={getAthleteTagTextStyling(data, 'mobile')}
							desktopPosition={getAthleteTagOverlayPosition(data, 'desktop')}
							desktopTextStyle={getAthleteTagTextStyling(data, 'desktop')}
							mediaPosition={mediaPosition}
						/>
					)}
				</MediaItem>

				{/*
					Rendering two different versions of the Hotspots, one for mobile, one for desktop. CoreMedia can produce
					potentially different content on desktop and mobile. The `isMobile` flag activates a css class to hide or show.
				*/}
				{mobileHotSpots && (
					<Hotspots hotspots={mobileHotSpots.map((h) => h.toUniversal())} analytics={mobileHotspotAnalytics} isMobile />
				)}
				{desktopHotSpots && (
					<Hotspots hotspots={desktopHotSpots.map((h) => h.toUniversal())} analytics={desktopHotspotAnalytics} />
				)}
				{/*
					Featured Products were intentionally placed this outside of the `MediaItem component but inside the div.
					The wrapper is what keeps its positioning and allows this to move around. This was done to illustrate that
					it is ok and still works. As we refactor out of these "all-in-one" components, we will need to extract
					features like AthleteTags and FeaturedProducts out in to each module to fully allow or prevent them. Right
					now, if `featuredProducts` exist -- it will render on all components using `MediaContent` but if we want to
					disallow them on a TIG - it is not easily done.
				*/}
				{featuredProducts && <FeaturedProducts {...featuredProducts} />}
			</div>
		)
	} catch (err) {
		logger.error(err)
		return null
	}
}
export default MediaContent
