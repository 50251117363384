import clsx from 'clsx'
import { useMemo } from 'react'

import type { AthleteInfo } from '~/lib/cms/legacy/athlete-tags'
import { getAthleteData } from '~/lib/cms/legacy/athlete-tags'
import type { ModuleTextStyle, SimpleOverlayPosition } from '~/lib/cms/legacy/helper'

import styles from './AthleteTag.module.scss'

interface Props {
	/** Array of Athlete Data */
	athletes: AthleteInfo[]

	/** The mobile location of the Athlete Tags. */
	mobilePosition?: SimpleOverlayPosition

	/** The style of the mobile version. Defaults to `undefined` which is dark text by css */
	mobileTextStyle?: ModuleTextStyle

	/** The desktop location of the Athlete Tags. */
	desktopPosition?: SimpleOverlayPosition

	/** The style of the desktop version. Defaults to `undefined` which is dark text by css */
	desktopTextStyle?: ModuleTextStyle

	/** Media position, this can be 0 or 1 (for the 2 possible media items in the array) */
	mediaPosition?: number // optional setting - this is currently a hack to grab different athlete groups.
}

/** Athlete Tag is a componenet used to show the name and title of one or two featured athletes shown in the image it is attached to. Athlete Tags are available on almost every content driven image and can be positioned in any of the four corners of the image as well as be shown in either black or white text.  */
const AthleteTag = ({
	athletes,
	mobilePosition = 'hidden',
	mobileTextStyle,
	desktopPosition = 'hidden',
	desktopTextStyle,
	mediaPosition = 0,
}: Props) => {
	const athleteData = useMemo(() => getAthleteData(athletes, mediaPosition), [athletes, mediaPosition])
	if (!athleteData) return null

	const wrapperClasses = clsx(styles.athletes__wrapper, {
		[styles[`mb-${mobilePosition}`]]: mobilePosition,
		[styles[`dt-${desktopPosition}`]]: desktopPosition,
		[styles['mb-light-text']]: mobileTextStyle === 'light-text',
		[styles['dt-light-text']]: desktopTextStyle === 'light-text',
	})

	return (
		<div className={wrapperClasses}>
			{athleteData.map((athlete, index) => (
				<div key={`athlete-${index}`}>
					{athlete?.displayName && <div className={`${styles['athlete-name']}`}>{athlete.displayName}</div>}
					{athlete?.jobTitle && <div>{athlete.jobTitle}</div>}
				</div>
			))}
		</div>
	)
}
export default AthleteTag
