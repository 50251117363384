import type { CmPerson, CmTeasable, LinkedContentEntry, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import { isNonNullish } from '~/types/strict-null-helpers'
import type { ModuleTextStyle, ModuleView, SimpleOverlayPosition } from './helper'
import { getLinkedContentSetting, getSimpleOverlayPosition } from './helper'
import { isLinkedAthlete } from './type-guard'

export type AthleteInfo = Pick<CmPerson, 'displayName' | 'jobTitle'>

/**
 * Builds `AthleteInfo` from supplied `athlete` and optional setting
 * @param athlete
 * @param setting
 * @returns
 */
export function getSingleAthleteData(
	athlete,
	setting: Maybe<LinkedContentEntry> | undefined = undefined,
): AthleteInfo | null {
	if (athlete?.customized) {
		return {
			displayName: athlete?.customName || '',
			jobTitle: athlete?.customPosition || '',
		}
	}
	if (!setting || !setting.value) return null

	const singleAthleteData = setting.value.find((obj) => obj && isLinkedAthlete(obj))
	if (!singleAthleteData || !isLinkedAthlete(singleAthleteData)) return null

	const combinedName = `${singleAthleteData.firstName} ${singleAthleteData.lastName}`
	const displayName =
		singleAthleteData?.displayName && singleAthleteData.displayName !== ''
			? singleAthleteData.displayName
			: combinedName
	return {
		displayName,
		jobTitle: singleAthleteData?.jobTitle || '',
	}
}

/**
 * Evaluates a `CmTeasable` object to check if it has `athletes` in it, if it does, it returns`AthleteInfo[]`
 * @param teasable
 * @returns
 */
export function getAthleteTags(teasable: CmTeasable): AthleteInfo[] | null {
	const linkedSettings = teasable?.uaModuleLinkSettings
	const athletes = teasable?.uaModuleSettings?.athletes

	if (!linkedSettings || !athletes) return null

	const allAthleteData: AthleteInfo[] = athletes
		.map((athlete) => {
			if (!athlete || !athlete?.target) return null
			const setting = linkedSettings.find((obj) => obj && obj.key === athlete.target)

			return getSingleAthleteData(athlete, setting)
		})
		.filter(isNonNullish)

	return allAthleteData?.length > 0 ? allAthleteData : null
}

/**
 * Simple function to find data for the `athletesOverlay` or `athletesMobileOverlay`, returns undefined if not
 * @param teasable
 * @param view
 * @returns
 */
export function getAthleteSettings(teasable: CmTeasable, view: ModuleView = 'mobile') {
	const { uaModuleSettings } = teasable
	if (!uaModuleSettings) return undefined

	// if `athletes`, `athleteOverlay` data or the view of `athleteOverlay.[desktop|mobile]` are not there or false - hide tags for that view
	const { athletes, athletesOverlay } = uaModuleSettings
	if (!athletes || !athletesOverlay || !athletesOverlay?.[view]) return undefined // athletesOverlay?.[view] is a boolean if it's shown on the view

	// overlay data lives in two spots for athlete tags - in the above `athletesOverlay` (desktop) and in `athletesMobileOverlay`
	return view === 'mobile' ? uaModuleSettings?.athletesMobileOverlay : athletesOverlay
}

/**
 * Get position placement of `athleteTags` icon for either 'desktop' or 'mobile' and will return `SimpleOverlayPosition` for selected view.
 * @param teasable
 * @param view `mobile` or `desktop`, used to specify the view settings needed
 * @returns
 */
export function getAthleteTagOverlayPosition(teasable: CmTeasable, view: ModuleView = 'mobile'): SimpleOverlayPosition {
	const athletesOverlayView = getAthleteSettings(teasable, view)
	if (!athletesOverlayView) return 'hidden'

	// since `athletesOverlayView` is not typed but the code has made it this far, set an assumed/fallback of 0 for each position
	const positionX = athletesOverlayView?.positionX || 0
	const positionY = athletesOverlayView?.positionY || 0

	return getSimpleOverlayPosition(positionX, positionY)
}

/**
 * Evaluate a `CmTeasable` object to get `linkedStyles` for Athlete Tags based on view.
 * @param teasable
 * @param view `mobile` or `desktop`, used to specify the view settings needed
 * @returns string | undefined ex: `dark-text`
 */
export function getAthleteTagTextStyling(
	teasable: CmTeasable,
	view: ModuleView = 'mobile',
): ModuleTextStyle | undefined {
	const athleteSetting = getAthleteSettings(teasable, view)
	if (!athleteSetting) return undefined

	const { linkedStyles } = athleteSetting
	if (!linkedStyles) return undefined

	const setting =
		teasable?.uaModuleLinkSettings && teasable.uaModuleLinkSettings.find((obj) => obj && obj.key === linkedStyles)
	if (!setting) return undefined

	return getLinkedContentSetting(setting) ? (getLinkedContentSetting(setting) as ModuleTextStyle) : undefined
}

/**
 * we can only render 2 athletes per image, so this data will only grab two athletes from the array,
 * the media position of 0 grabs the first 1, the media position of 1 grabs the second 2
 * @param athletes
 * @param mediaPosition
 * @returns
 */
export function getAthleteData(athletes: AthleteInfo[], mediaPosition: number) {
	if (!athletes || athletes.length <= 0) return null

	const athleteSliceStart = mediaPosition === 1 ? 2 : 0
	const athleteSliceEnd = mediaPosition === 1 ? 4 : 2

	const athleteData = athletes.slice(athleteSliceStart, athleteSliceEnd)
	return athleteData.length > 0 ? athleteData : null
}
