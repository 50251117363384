import { cloneElement, createElement, memo, type AllHTMLAttributes } from 'react'
import { type Optional } from 'types/strict-null-helpers'
import { getContentIdFromCmIdentifier, getUrlFromReferenceObject } from '~/lib/cms/legacy/helper'
import type { Element } from '~/lib/types/coremedia.interface'
import styles from './ElementTree.module.scss'
import type { Content } from '~/graphql/generated/coremedia/type-document-node'
import logger from '~/lib/logger'

interface ElementTreeProps {
	element: Element
	refs?: Optional<Content[]>
	locale: string
}

/**
 * Get the value of an attribute from an element.  Matches on Element.name
 * @param element
 * @param attribute
 * @returns
 */
function getAttribute(element: Element, attribute: string) {
	return element.attributes.find((el) => el.name === attribute)?.value
}

/**
 * Creates an object of HTML attributes for a given element based on the values
 * stored in the element's attributes array and the refs array.  For example, if
 * the element is an image, the function will look for a data-src attribute and
 * use that to look up the URL in the refs array.  If the element is a link, the
 * function will look for a data-href attribute and use that to look up the URL
 * in the refs array.
 * @param element
 * @param refs
 * @param locale
 * @returns
 */
function getProperties(
	element: Element,
	refs: Content[] | undefined | null,
	locale: string,
): AllHTMLAttributes<HTMLElement> {
	// find element name/tagzx
	if (element.name === 'p') {
		if (element.children?.[0].name === 'br') {
			return { className: styles['align--break'] }
		}

		if (element.children?.[0].name === 'img') {
			return { className: styles['align--img'] }
		}
	} else if (element.name === 'img') {
		if (refs) {
			const cmIdentifier = getAttribute(element, 'data-src')
			if (cmIdentifier) {
				const id = getContentIdFromCmIdentifier(cmIdentifier)
				if (id) {
					const src = getUrlFromReferenceObject(refs, id, locale)
					if (src) {
						return {
							className: styles['align--img'],
							src,
						}
					}
					logger.warn('Unable to build image src attribute for element because URL not found')
				} else {
					logger.warn(
						'Unable to build image src attribute for element because data-src found but coremedia identifier URI not found',
					)
				}
			} else {
				logger.warn('Unable to build image src attribute for element because data-src not found or invalid')
			}
		} else {
			logger.warn('Unable to build image src attribute for element because there is no refs block')
		}
	} else if (element.name === 'a') {
		if (refs) {
			// The HREF value is set if the link is external.  In this case we can
			//	simply use the value as is and return the properties of a standard anchor tag.
			const rawUrl = getAttribute(element, 'href')
			if (rawUrl) {
				return {
					className: styles['align--link'],
					href: rawUrl,
					target: '__blank',
				}
			}

			// The data-href value is set if the link is internal.  In this case we need
			//	to look up the content ID and then use that to look up the URL.
			const cmIdentifier = getAttribute(element, 'data-href')
			if (cmIdentifier) {
				const id = getContentIdFromCmIdentifier(cmIdentifier)
				if (id) {
					const href = getUrlFromReferenceObject(refs, id, locale)
					if (href) {
						return {
							className: styles['align--link'],
							href,
						}
					}
					logger.warn('Unable to build link href attribute for element because URL not found')
				} else {
					logger.warn(
						'Unable to build link href attribute for element because data-href found but coremedia identifier URI not found',
					)
				}
			} else {
				logger.warn('Unable to build link href attribute for element because data-href not found or invalid')
			}
		} else {
			logger.warn('Unable to build link href attribute for element because there is no refs block')
		}
	}

	return {
		className: element.attributes.map((attribute) => styles[attribute.value]).join(' '),
	}
}

/**
 * Creates an element tree from a given element.  If the element has children,
 * the function will recursively call itself to create the children.
 * @param element
 * @param locale
 * @param refs
 * @returns
 */
function createElementTree(element: Element, locale: string, refs?: Optional<Content[]>) {
	// Loop through the elements children. If data exists, show it, if not look for child elements
	const children = (childElements: Element[], refs) =>
		childElements.map((child: Element, index) =>
			child?.data ? child.data : cloneElement(createElementTree(child, locale, refs), { key: index }),
		)
	// Create the element tree and assign attributes
	if (element?.name) {
		return createElement(
			element.name,
			getProperties(element, refs, locale),
			element.children && children(element.children, refs),
		)
	}

	return undefined
}

export default memo(function ElementTree({ element, refs, locale }: ElementTreeProps) {
	return createElementTree(element, locale, refs)
})
