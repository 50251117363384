/* eslint-disable @typescript-eslint/naming-convention */
/**
 * The module settings map is compiled of parameters that are coming from individual CoreMedia templates.
 * In each template there are a set of paramaters that give defaults for the module. The key also
 * corresponds with the "viewType" class we are returning in our query.
 * There is a mapping function in the General Banner that should likely be extracted in to a global
 * function to process the request
 */

export interface ModuleSettings {
	additionalClass: string
	desktopImageRecipes?: string[]
	headlineStyle?: string
	desktopTeaserOverlay?: boolean
	desktopCarousel?: boolean
	desktopCopyOverlayWrapper?: boolean
	desktopColumnsCount?: number
	copyAreaView?: string
	mobileCarousel?: boolean
	mobileInlineWrapper?: boolean
	mobileImageRecipes?: string[]
	mobileTeaserOverlay?: boolean
	inGridSetting?: string
	textOnly?: boolean
	seoSingle?: boolean
	/** LEGACY SUPPORT: designed to memic uaModuleSettings.desktopDirection - if true - alight right, else align left */
	desktopDirection?: boolean
	/** LEGACY SUPPORT: full width copy */
	desktopFullwidthCopy?: boolean
	/** LEGACY SUPPORT: designed to memic uaModuleSettings.mobileDirection - if true - alight right, else align left */
	mobileDirection?: boolean
	/** LEGACY SUPPORT: added to use a predefined width from css, sometimes the graph response is adding a default width of 50% even when there is no width setting on the module. the override is temporary to render content correctly. */
	useCssDefinedWidth?: boolean
	/** LEGACY SUPPORT: added because legacy storytelling modules inverted media */
	invertMedia?: boolean
	/** LEGACY SUPPORT: added for storytelling 50/50 to hide teaser */
	hideTeaser?: boolean
}
interface SettingsMap {
	[key: string]: ModuleSettings
}
export const moduleSettingsMap: SettingsMap = {
	'cm-article': {
		additionalClass: '',
		desktopImageRecipes: ['recipe-hero-mb-wide'],
	},
	'dt-general-banner': {
		additionalClass: 'module--full-width-hero-dt',
		desktopTeaserOverlay: true,
		desktopCarousel: true,
		headlineStyle: 'headline1',
		desktopImageRecipes: ['recipe-hero-singleimage'],
		desktopCopyOverlayWrapper: true,
	},
	'dt-general-banner-text-box': {
		additionalClass: 'module--full-width-hero-dt module--textbox-dt',
		desktopTeaserOverlay: true,
		desktopCarousel: true,
		headlineStyle: 'headline1',
		desktopImageRecipes: ['recipe-hero-singleimage'],
		desktopCopyOverlayWrapper: true,
	},
	'dt-category-hero': {
		additionalClass: 'module--category-hero-dt module--textbox-dt module--category-hero-mb ',
		headlineStyle: 'headline1',
		desktopImageRecipes: [],
		copyAreaView: 'categoryNavigation',
		desktopCopyOverlayWrapper: false,
		useCssDefinedWidth: true,
	},
	'dt-split-banner': {
		additionalClass: 'module--split-dt',
		headlineStyle: 'headline1',
		desktopImageRecipes: ['recipe-hero-split-primary', 'recipe-hero-split-secondary'],
		useCssDefinedWidth: true,
	},
	'dt-storytelling-split': {
		additionalClass: 'module--storytelling-split-dt',
		desktopCarousel: true,
		mobileInlineWrapper: true,
		useCssDefinedWidth: true,
	},
	'dt-split-banner-black': {
		additionalClass: 'module--split-dt module--black-bg-dt',
		headlineStyle: 'headline1',
		desktopImageRecipes: ['recipe-hero-split-primary', 'recipe-hero-split-secondary'],
		useCssDefinedWidth: true,
	},
	'dt-fiftyfifty-banner': {
		additionalClass: 'module--fiftyfifty-dt',
		headlineStyle: 'headline2',
		desktopImageRecipes: ['recipe-banner-5050', 'recipe-banner-5050'],
	},
	'dt-bucket-3up': {
		additionalClass: 'module--3up-dt',
		desktopColumnsCount: 3,
	},
	'dt-bucket-3column': {
		additionalClass: '',
		desktopColumnsCount: 3,
		desktopCarousel: true,
	},
	'dt-bucket-4column': {
		additionalClass: '',
		desktopColumnsCount: 4,
		desktopCarousel: true,
		mobileImageRecipes: ['recipe-storytelling-3up'],
	},
	'dt-bucket-router': {
		additionalClass: 'module--router-dt',
		desktopColumnsCount: 2,
		desktopImageRecipes: ['recipe-bucket-router'],
	},
	'dt-bucket-router-wide': {
		additionalClass: 'module--router-wide-dt',
		desktopColumnsCount: 2,
		desktopImageRecipes: ['recipe-bucket-router'],
	},
	'dt-bucket-scattered': {
		additionalClass: 'module--scattered-dt',
	},
	'dt-in-grid-single': {
		additionalClass: 'module--in-grid-single-dt',
		headlineStyle: 'headline4',
		desktopImageRecipes: ['recipe-sig'],
		inGridSetting: 'dt-sig mb-sig',
	},
	'dt-in-grid-double': {
		additionalClass: 'module--in-grid-double-dt',
		headlineStyle: 'headline4',
		desktopImageRecipes: ['recipe-double-in-grid'],
		inGridSetting: 'dt-dig mb-dig',
	},
	'dt-in-grid-triple': {
		additionalClass: 'module--in-grid-triple-dt module--textbox-dt',
		desktopCarousel: true,
		headlineStyle: 'headline3',
		desktopImageRecipes: ['recipe-triple-in-grid'],
		inGridSetting: 'dt-tig mb-dig',
	},
	'dt-in-grid-triple-split': {
		additionalClass: 'module--in-grid-triple-split-dt module--textbox-dt',
		desktopCarousel: true,
		useCssDefinedWidth: true,
		headlineStyle: 'headline3',
		inGridSetting: 'dt-tig mb-dig',
		desktopImageRecipes: ['default'],
	},
	'dt-storytelling-organic': {
		additionalClass: 'module--storytelling-organic-dt',
		desktopImageRecipes: ['recipe-storytelling-organic-secondary', 'recipe-storytelling-organic-primary'],
		useCssDefinedWidth: true,
		headlineStyle: 'default',
	},
	/**
	 * BELOW ARE LEGACY MODULES WITH BACKDATED SUPPORT
	 * Generally anything not starting `dt` is legacy
	 */
	'hero-singleimage-snipe-3': {
		// LISTED AS LEGACY IN CM CODE, LIKELY REMOVE
		additionalClass: 'module--full-width-hero-dt module--dark-text-dt module--full-width-hero-mb module--dark-text-mb',
		desktopTeaserOverlay: true,
		mobileTeaserOverlay: true,
		desktopDirection: true,
		mobileDirection: true,
		headlineStyle: 'headline3',
		desktopImageRecipes: ['recipe-hero-singleimage'],
		mobileImageRecipes: ['recipe-hero-mb-wide'],
	},
	'hero-3section-4': {
		// LISTED AS LEGACY IN CM CODE, LIKELY REMOVE
		additionalClass:
			'module--3section-dt module--dark-text-dt module--right-dt module--split-mb module--textbox-mb module--dark-text-mb module--right-mb',

		mobileInlineWrapper: true,
		headlineStyle: 'headline2',
		desktopImageRecipes: ['recipe-hero-3section-primary', 'recipe-hero-3section-secondary'],
		mobileImageRecipes: ['recipe-hero-split-mb', 'recipe-hero-split-mb'],
	},
	/**
	 * Legacy - Storytelling
	 */
	'storytelling-full-image': {
		additionalClass: 'module--full-width-hero-dt module--full-width-hero-mb module-story-telling-full-width',
		desktopTeaserOverlay: true,
		mobileTeaserOverlay: true,
		desktopCarousel: true,
		mobileCarousel: true,
		desktopImageRecipes: ['recipe-hero-singleimage'],
		mobileImageRecipes: ['recipe-hero-mb-wide'],
	},
	'storytelling-split-1': {
		additionalClass: 'module--storytelling-split-dt module--storytelling-split-mb',
		desktopCarousel: true,
		desktopDirection: true,
		mobileCarousel: true,
		mobileInlineWrapper: true,
		useCssDefinedWidth: true,
	},
	'storytelling-split-2': {
		additionalClass: 'module--storytelling-split-dt module--storytelling-split-mb',
		desktopCarousel: true,
		mobileCarousel: true,
		mobileInlineWrapper: true,
		useCssDefinedWidth: true,
	},
	'storytelling-organic-1': {
		additionalClass: 'module--storytelling-organic-dt module--storytelling-organic-mb',
		desktopDirection: true,
		mobileDirection: true,
		invertMedia: true,
		headlineStyle: 'headline6',
		mobileInlineWrapper: true,
		useCssDefinedWidth: true,
	},
	'storytelling-organic-2': {
		additionalClass: 'module--storytelling-organic-dt module--storytelling-organic-mb',
		invertMedia: true,
		headlineStyle: 'headline6',
		mobileInlineWrapper: true,
		useCssDefinedWidth: true,
	},
	'storytelling-split-image': {
		additionalClass: 'module--fiftyfifty-dt module--fiftyfifty-mb',
		hideTeaser: true,
		desktopImageRecipes: ['recipe-banner-5050', 'recipe-banner-5050'],
		mobileImageRecipes: ['recipe-hero-split-mb', 'recipe-hero-split-mb'],
	},
	/**
	 * Legacy - In Grid
	 */
	'single-in-grid': {
		additionalClass: 'module--in-grid-single-dt module--in-grid-single-mb',
		headlineStyle: 'headline4',
		desktopImageRecipes: ['recipe-single-in-grid'],
		mobileImageRecipes: ['recipe-single-in-grid'],
		inGridSetting: 'dt-sig mb-sig',
	},
	'double-in-grid-seo-1': {
		// legacy ?
		additionalClass: 'module--in-grid-double-dt module--in-grid-double-mb module--marketing-text',
		headlineStyle: 'headline4',
		desktopImageRecipes: ['recipe-dig'],
		mobileImageRecipes: ['recipe-dig'],
		inGridSetting: 'dt-dig mb-dig',
		desktopFullwidthCopy: true,
	},
	'triple-in-grid-seo': {
		additionalClass: 'module--textOnly-dt  module--textOnly-mb',
		inGridSetting: 'dt-tig mb-dig',
		desktopFullwidthCopy: true,
		textOnly: true,
	},
	'triple-in-grid-1': {
		additionalClass: 'module--in-grid-triple-dt module--textbox-dt module--full-width-hero-mb module--textbox-mb',
		inGridSetting: 'dt-tig mb-dig',
		desktopDirection: true,
	},
	'triple-in-grid-4': {
		additionalClass: 'module--in-grid-triple-dt module--textbox-dt module--full-width-hero-mb module--textbox-mb ',
		desktopCarousel: true,
		mobileCarousel: true,
		mobileInlineWrapper: true,
		headlineStyle: 'headline3',
		desktopImageRecipes: ['recipe-triple-in-grid'],
		mobileImageRecipes: ['recipe-hero-mb-wide'],
		inGridSetting: 'dt-tig mb-dig',
	},
	'single-in-grid-seo': {
		additionalClass: 'module--dark-text-dt module--dark-text-mb module--marketing-text',
		inGridSetting: 'module__single-in-grid-seo dt-sig mb-dig',
		textOnly: true,
		seoSingle: true,
	},
}
