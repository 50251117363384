import type { CmMedia, UaMedia } from '~/graphql/generated/coremedia/type-document-node'
import { type RecipeParams } from './scene7-recipes'
import { isUaMedia } from '~/lib/cms/legacy/type-guard'

export interface ImageDimensions {
	height: number
	width: number
}

/**
 * @param width Scene7 Width Number
 * @param imageName: Image Name, usually formatted like: `FW22_TRN_Cogender_Meridian_Site_2_1_COG_US`
 * @param isDesktop: optional, Bool, if no ratio is found - desktop will generate 2:1 and mobile 1:1
 * @returns
 */
export const createMediaAspectRatio = (width: number, imageName: string, isDesktop?: boolean) => {
	/**
	 * This will find the pattern _Number1_Number1 and return Number1_Number1, Number1, Number2
	 * Number1 = Width
	 * Number2 = Height
	 */

	const regEx = /_([0-9]+)_([0-9]+)_?([0-9]+)?/g

	const imageRatio = regEx.exec(imageName)

	if (imageName !== '' && imageRatio != null && imageRatio.length > 1) {
		const widthRatio = parseInt(imageRatio[1], 10)
		const heightRatio = parseInt(imageRatio[2], 10)

		let adjustedWidthRatio = widthRatio
		switch (widthRatio) {
			case 165:
			case 1.65:
				adjustedWidthRatio = 1.65
				break
			case 225:
				adjustedWidthRatio = 2.25
				break
		}

		const adjustedRatio = (width * heightRatio) / adjustedWidthRatio

		// coremedia naming convention on files of x_x being an image ratio is not always
		// upheld and can cause an image to ballon in size
		return adjustedRatio // > MAX_SIZE ? MAX_SIZE : adjustedRatio
	}
	return isDesktop ? width / 2 : width
}
/**
 * The best guess at the image dimensions. This function takes the `mediaAssetId` and if it's mobile or desktop and will return image
 * dimensions based on that using regex to break down the name of the file to assume the proper aspect ratio. This is not a preferred
 * way of evaluating an image but is used as a fallback which is why this is not exported.
 * @param mediaAssetId
 * @param isDesktop
 * @returns a best guess of what the height and width of an image should be based off of the name of the file
 */
export const bestGuessImageDimensions = (mediaAssetId: string, isDesktop: boolean): ImageDimensions => {
	const width = isDesktop ? 1440 : 414 // starting width. max width on desktop is 1440, max width on CM movile is 767
	const height = Math.round(createMediaAspectRatio(width, mediaAssetId, isDesktop))
	return { height, width }
}

/**
 * Take a height and width of an image and return the ratio of the height. This is used to leverage provided height and width imfornation,
 * to compare to a recipe and to rescale the proper height of an image for it's placement on the site. It will help prevent an image too
 * large being used in the incorrect spot.
 * @param width - number
 * @param height - number
 * @returns width/height in a percentage, examples would be w: 1440, h: 720, the return would be 2
 */
export const calculateImageAspectRatioForUaMedia = (width: number, height: number) => {
	if (!height || !width) return undefined
	return Math.round((width / height) * 100) / 100
}

/**
 * Completely build an image's dimensions based on our order of opperations. This will take an image and compare it to a supplied recipe,
 * native image size and location (desktop or mobile) and return an object for height and width. This will first look at the image recipe,
 * if there are H/W supplied, that will return as the source of truth, this will prevent an 800x800 image being rendered in an ingrid if
 * incorrectly supplied by the content editor.
 * @param image - either a `UaMedia` or `CmMedia` item
 * @param isDesktop - boolean for desktop or mobile
 * @param recipe - the image recipe, which is a return of RecipeParams
 * @returns an object of height/width or null
 */
export const getImageDimensions = (
	image: UaMedia | CmMedia,
	isDesktop: boolean,
	recipe?: RecipeParams | null,
): ImageDimensions | null => {
	if (!image) return null
	// Height and Width are standard on the UAMedia object and are part of the import as of ~11/1/23
	const height = isUaMedia(image) && image?.height ? image.height : undefined
	const width = isUaMedia(image) && image?.width ? image.width : undefined

	const imageRatioFromUaImage = height && width && calculateImageAspectRatioForUaMedia(width, height)
	const mediaAssetId = isUaMedia(image) ? image.externalAssetId : image.uaExternalAssetId

	// If there is a recipe, this is the most accurate way to create the image size.
	if (recipe) {
		const { hei, wid } = recipe
		if (hei) return { height: hei, width: wid }

		// If there is not a provided height, but one can be assumed from the provided image ratio, return the dimensions using the provided width and calculated height
		const calculatedFromKnownRatio = imageRatioFromUaImage && Math.round(wid / imageRatioFromUaImage)
		if (calculatedFromKnownRatio) return { height: calculatedFromKnownRatio, width: wid }
	}

	// If both height and width exist on the image and parameters have not been returned yet, return the image in it's native size
	if (height && width) return { height, width }

	// If a there is a mediaAssetId, we can create a ratio off of that.
	if (mediaAssetId) {
		// Check to see if there is a recipe width available, if so, create dimensions based on that
		const widthFromRecipe = recipe?.wid
		if (widthFromRecipe) {
			const heighCreatedWithImageName = Math.round(createMediaAspectRatio(widthFromRecipe, mediaAssetId, isDesktop))
			if (heighCreatedWithImageName) return { height: heighCreatedWithImageName, width: widthFromRecipe }
		}
		// If there is no recipe, height or width, but a `mediaAssetId` return the bestGuessImageDimensions
		return bestGuessImageDimensions(mediaAssetId, isDesktop)
	}

	// If there is no `mediaAssetId` and no other possible calulations exist, return null **this should never happen**
	return null
}

interface ImageRatios {
	widthRatio: number | undefined
	heightRatio: number | undefined
}
/**
 * Extracts image width and height ratios from the image name.
 * @param {string} imageName - The name of the image.
 * @returns {{ widthRatio: number | undefined, heightRatio: number | undefined }} An object containing the width and height ratios extracted from the image name.
 */
export const extractImageRatioFromName = (imageName: string): ImageRatios => {
	const regEx = /_([0-9]+)_([0-9]+)_?([0-9]+)?/g

	let widthRatio: number | undefined
	let heightRatio: number | undefined

	const imageRatio = regEx.exec(imageName)
	if (imageName !== '' && imageRatio != null && imageRatio.length > 1) {
		widthRatio = parseInt(imageRatio[1], 10)
		heightRatio = parseInt(imageRatio[2], 10)
	}

	return { widthRatio, heightRatio }
}

// TODO: We could probably make both of these functions below more generally applicable, so that different modules have a subset of "supportedRatios" that we validate against and then the getValidImageRatio would also take in some sort of other arg to identify the subset to validate against (i.e. "moduleType"). These methods are used because rather than being interested in a pixel height or width, we are leveraging aspectRatio for content carousel modules and this is a more direct route to get there.
/**
 * Checks if the given image ratios are supported for use in a carousel.
 * @param {ImageRatios} imageRatios - An object containing width and height ratios.
 * @returns {ImageRatios} An object containing the width and height ratios if they are supported, otherwise returns an object with undefined ratios.
 */
export const isSupportedCarouselRatios = (imageRatios: ImageRatios) => {
	const supportedRatios = [
		{ width: 1, height: 1, wid: 767 },
		{ width: 4, height: 5, wid: 767 },
		{ width: 5, height: 4, wid: 900 },
		{ width: 8, height: 5, wid: 767 },
	]

	const { widthRatio, heightRatio } = imageRatios

	const foundRatio = supportedRatios.find((ratio) => ratio.width === widthRatio && ratio.height === heightRatio)

	return foundRatio?.wid
}

export const getValidImageRatioForCarousel = (imageName: string) => {
	const imageRatios = extractImageRatioFromName(imageName)
	const widParam = isSupportedCarouselRatios(imageRatios)

	return widParam
		? { ...imageRatios, wid: widParam }
		: { widthRatio: undefined, heightRatio: undefined, wid: undefined }
}
