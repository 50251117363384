import type {
	CmArticle,
	CmCollection,
	CmExternalChannel,
	CmExternalLink,
	CmExternalPage,
	CmExternalProduct,
	CmLinkable,
	CmMedia,
	CmObject,
	CmPerson,
	CmPicture,
	CmProductTeaser,
	CmSymbol,
	CmTeasable,
	CmVideo,
	CmViewtype,
	Content,
	ExtendedTeaserTarget,
	UaBucket,
	UaMedia,
} from '~/graphql/generated/coremedia/type-document-node'
import type { TextStyle } from '~/lib/client-server/cms/modules'

// type guards
export type CoreMediaType =
	| CmLinkable
	| CmTeasable
	| CmCollection
	| CmArticle
	| CmProductTeaser
	| CmPicture
	| CmVideo
	| CmPerson
	| CmMedia
	| UaBucket

export type CoreMediaLink = CmExternalLink | CmExternalPage | CmExternalChannel | CmExternalProduct

export type CoreMediaObject = CoreMediaLink | CoreMediaType | ExtendedTeaserTarget

export function isCoreMediaExternalTarget(x: unknown): x is ExtendedTeaserTarget {
	return (
		typeof x === 'object' &&
		x !== null &&
		'callToActionEnabled' in x &&
		typeof x.callToActionEnabled === 'boolean' &&
		'target' in x &&
		isCoreMediaLink(x.target)
	)
}

/**
 * With no information about the value being passed in, this will narrow the type to one of the core media
 * object types
 * @param x
 * @returns
 */
export function isCoreMediaType(x: unknown): x is CoreMediaType {
	return (
		typeof x === 'object' &&
		x !== null &&
		'type' in x &&
		typeof x.type === 'string' &&
		(x.type.startsWith('CM') || x.type.startsWith('UA'))
	)
}

// need to clean this up - this mirrors the `isUaMedia` but should be strengthed
export const isCoreMediaUaMedia = (x: unknown): x is UaMedia => (x as UaMedia).type === 'UAMedia'

export function isCoreMediaObject(x: unknown): x is CoreMediaObject {
	return !!(isCoreMediaType(x) || isCoreMediaExternalTarget(x) || isCoreMediaUaMedia(x))
}

/**
 * With no information about the value being passed in, this will narrow the type to one of the core media
 * object types that represents a link to another piece of content
 * @param x
 * @returns
 */
export function isCoreMediaLink(x: unknown): x is CoreMediaLink {
	return (
		typeof x === 'object' &&
		x !== null &&
		'type' in x &&
		typeof x.type === 'string' &&
		(x.type.startsWith('CMExternal') || x.type === 'CMProductTeaser' || x.type === 'UABucket')
	)
}

export function isCoreMediaPicture(x: unknown): x is CmPicture {
	return isCoreMediaType(x) && x.type === 'CMPicture'
}

// main module types
export const isCmTeaser = (x: unknown): x is CmTeasable => isCoreMediaType(x) && x.type === 'CMTeaser'
export const isCmImageMap = (x: unknown): x is CmTeasable => isCoreMediaType(x) && x.type === 'CMImageMap'
export const isCmCollection = (x: CmLinkable): x is CmCollection => x.type === 'CMCollection'
export const isCmArticle = (x: CmLinkable): x is CmTeasable => x.type === 'CMArticle'
export const isCmUaBucket = (x: unknown): x is UaBucket => isCoreMediaType(x) && x.type === 'UABucket'

// linkable types
export const isCmExternalLink = (x: CmLinkable): x is CmExternalLink => x.type === 'CMExternalLink'
export const isCmExternalPage = (x: CmLinkable): x is CmExternalPage => x.type === 'CMExternalPage'
export const isCmExternalChannel = (x: CmLinkable): x is CmExternalChannel => x.type === 'CMExternalChannel'
export const isCmExternalProduct = (x: unknown): x is CmExternalProduct | CmProductTeaser =>
	isCoreMediaType(x) && (x.type === 'CMExternalProduct' || x.type === 'CMProductTeaser')

// media types
export const isCmPicture = (x: CmMedia): x is CmPicture => x.type === 'CMPicture'
export const isCmVideo = (x: CmMedia): x is CmVideo => x.type === 'CMVideo'
export const isUaMedia = (x: UaMedia | CmMedia): x is UaMedia => x.type === 'UAMedia'
export const isUaMediaPicture = (x: UaMedia): x is UaMedia => x.mediaType === 'CMPicture'
export const isUaMediaVideo = (x: UaMedia): x is UaMedia => x.mediaType === 'CMVideo'

// object types
export const isLinkedViewType = (x: unknown): x is CmViewtype => isCoreMediaType(x) && x.type === 'CMViewtype'
export const isLinkedAthlete = (x: CmObject): x is CmPerson => x.type === 'CMPerson'
export const isLinkedSetting = (x: CmObject): x is CmSymbol => x.type === 'CMSymbol'
export const isLinkedVideo = (x: CmObject): x is CmVideo => x.type === 'CMVideo'
export const isLinkedSettingLinkable = (
	x: CmObject,
): x is CmExternalLink | CmExternalPage | CmExternalChannel | CmExternalProduct | CmProductTeaser =>
	x.type === 'CMExternalLink' ||
	x.type === 'CMExternalPage' ||
	x.type === 'CMExternalChannel' ||
	x.type === 'CMExternalProduct' ||
	x.type === 'CMProductTeaser'

// check if content is a supported link type
export const isLinkableContent = (
	x: Content,
): x is CmExternalLink | CmExternalPage | CmExternalChannel | CmExternalProduct | CmProductTeaser | CmArticle =>
	x.type === 'CMExternalLink' ||
	x.type === 'CMExternalPage' ||
	x.type === 'CMExternalChannel' ||
	x.type === 'CMExternalProduct' ||
	x.type === 'CMProductTeaser' ||
	x.type === 'CMArticle'

export const isTextStyle = (x: unknown): x is TextStyle =>
	x === 'headline1' ||
	x === 'headline2' ||
	x === 'headline3' ||
	x === 'headline4' ||
	x === 'headline5' ||
	x === 'headline6'
