/* eslint-disable no-underscore-dangle */
import type { CmLinkable } from '~/graphql/generated/coremedia/type-document-node'
import { isCmImageMap } from '~/lib/cms/legacy/type-guard'
import { CmHotzoneSchema, type CmHotzoneSafe } from '~/lib/client-server/cms/sources/coremedia/shared/schemas'
import { getBriefProductDataByAnyId, isProductUpc } from '~/lib/products'
import type { CmMediaImpl } from './media'
import type { Hotspot } from '~/lib/client-server/cms/modules/hotspot'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import { getListOfItemsInPlacement } from '~/lib/content'
import { standAloneApolloClient } from '~/lib/client-server/uacapi-client'
import { ensureString, isNonNullish } from '~/types/strict-null-helpers'

export class CmHotspotImpl {
	private _hotzone: CmHotzoneSafe
	private _parentMedia: CmMediaImpl

	constructor(hotspot: CmHotzoneSafe, media: CmMediaImpl) {
		this._hotzone = CmHotzoneSchema.parse(hotspot)
		this._parentMedia = media
	}

	toUniversal(): Hotspot {
		return {
			product: {
				productId: this.productId,
				style: this.style,
				color: this.color,
				upc: this.upc,
			},
			location: this.position || { left: -1, top: -1 },
		}
	}

	public get hotzone(): CmHotzoneSafe {
		return this._hotzone
	}

	public get productId() {
		return this._hotzone.linkedContent?.type === 'CMExternalProduct' ||
			this._hotzone.linkedContent?.type === 'CMProductTeaser'
			? this._hotzone.linkedContent.productRef.externalId
			: undefined
	}

	public get categoryId() {
		return this._hotzone.linkedContent?.type === 'CMExternalChannel'
			? this._hotzone.linkedContent.categoryRef.externalId
			: undefined
	}

	public get style() {
		return (
			((this._hotzone.linkedContent?.type === 'CMExternalProduct' ||
				this._hotzone.linkedContent?.type === 'CMProductTeaser') &&
				this._hotzone?.linkedContent?.uaSettings?.style) ||
			undefined
		)
	}

	public get color() {
		return (
			((this._hotzone.linkedContent?.type === 'CMExternalProduct' ||
				this._hotzone.linkedContent?.type === 'CMProductTeaser') &&
				this._hotzone.linkedContent?.uaSettings?.c_color) ||
			undefined
		)
	}

	public get upc() {
		return this.productId && isProductUpc(this.productId) ? this.productId : undefined
	}

	public get position() {
		const { width, height } = this._parentMedia
		if (!width || !height) return undefined

		const centerX = this._hotzone.centerX || 0
		const centerY = this._hotzone.centerY || 0

		// convert number to percentage
		const leftPercent = (centerX / width) * 100
		const topPercent = (centerY / height) * 100

		// Trim number from 85.3253562369 to 85.33
		const left = Math.round((leftPercent + Number.EPSILON) * 100) / 100
		const top = Math.round((topPercent + Number.EPSILON) * 100) / 100

		return {
			left,
			top,
		}
	}
}

export async function getProductByUnknownId(id: string) {
	return getBriefProductDataByAnyId(standAloneApolloClient(), { unknown: id })
}

export async function getProductsForHotspots(hotspotData: Hotspot[]) {
	const newlyMappedProducts = await Promise.all(
		hotspotData.map(async (h) => {
			// filter out products without productIds
			if (!h.product.productId) return undefined

			const processedHotspot = { ...h }
			/**
			 * queryId = what is passed to UACAPI as an "unknown" id.
			 *
			 * Data can come in two different ways from CM. Based on the type of product the users has linked.
			 * At this point the `productId` could be a style, or UPC.
			 *
			 * A new setting was added where the user can select a checkbox, "SKU", in CM that will attach additional
			 * product meta in the `uaSettings` property that includes the product `style` and `color`
			 *
			 * In many cases a specific product (size and color) are linked to the hotspot. When directly querying the
			 * product by the initial `productId` we often return null if that specific (size and color) are OOS.
			 *
			 * With the hotspots we want to render the hotspot if the product is instock or not and let the QATB modal
			 * drive the user experience. For that, we first look for the `style` prop, then fallback to `productId`
			 *
			 */
			const queryId = h.product.style || h.product.productId
			const product = await getProductByUnknownId(queryId)
			if (!product?.length || !product[0]) return undefined

			// update base properties with actual product data.
			processedHotspot.product.id = ensureString(product[0].id)
			processedHotspot.product.name = ensureString(product[0].copy?.name)
			// if we have a style already, there is no need to update it.
			if (!processedHotspot.product.style) {
				processedHotspot.product.style = ensureString(product[0].style)
			}

			return processedHotspot
		}),
	)
	return newlyMappedProducts.filter(isNonNullish)
}

function placementHasHotspots(items: CmLinkable[]) {
	return items?.some(
		(content) => isCmImageMap(content) && (content?.uaMedia?.[0]?.hotSpots || content?.uaMobileMedia?.[0]?.hotSpots),
	)
}

export function pageHasHotspots(pageContent: PageContentData) {
	const placements = pageContent?.placements.filter((p) => !!getListOfItemsInPlacement(p))
	if (!placements.length) return false
	for (let i = 0; i < placements.length; i++) {
		const items = getListOfItemsInPlacement(placements[i])
		if (placementHasHotspots(items)) return true
	}
	return false
}
