'use client'

import Head from 'next/head'
import { useState } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import CloseIcon from '~/components/primitives/icons/CloseIcon'
import PlayVideoIcon from '~/components/primitives/icons/PlayVideoIcon'
import type { CmObject, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import Video from './Video'
import styles from './VideoPlayer.module.scss'

interface Props {
	controls: boolean
	videoName: string // video assetID
	autoPlay: boolean
	loop: boolean
	muted: boolean
	poster?: string // video poster assetID
	/** Should play video as soon as user interacts with screen */
	shouldPlayOnIteraction?: boolean
	/** This attribute specifies the media that the linked video poster applies to */
	posterMedia?: string
	linkedVideo?: Maybe<CmObject> | undefined
	alt: string
}

/**
 * VideoPlayer
 * @param controls boolean | hide/show controls
 * @param videoName string | video assetID
 * @param autoPlay boolean | autoplay video
 * @param loop boolean | loop video
 * @param muted boolean | mute video
 * @param poster optional | poster assetID
 * @param alt included for screen reader compatibility
 */
const VideoPlayer = ({ controls, videoName, autoPlay, loop, muted, poster, posterMedia, linkedVideo, alt }: Props) => {
	const formatMessage = useFormatMessage()
	const [isPlaying, setIsPlaying] = useState(false)

	const handleVideoPlayerControls = () => {
		setIsPlaying(!isPlaying)
	}

	const scene7BaseUrl = `https://underarmour.scene7.com`
	const posterSrc = `${scene7BaseUrl}/is/image/Underarmour/${
		poster ?? videoName
	}?fit=constrain,1&wid=900&hei=506&fmt=jpg&qlt=75`

	return (
		<div className={styles.video__wrapper}>
			<Head>
				<link key={posterSrc} rel="preload" fetchPriority="high" as="image" href={posterSrc} media={posterMedia} />
			</Head>

			{linkedVideo ? (
				<>
					<button
						className={styles.video__play}
						type="button"
						onClick={handleVideoPlayerControls}
						aria-haspopup="true"
						aria-controls="video-player"
						aria-label={formatMessage('show-video')}
					>
						<PlayVideoIcon size="LG" />
					</button>
					<Video
						className={styles['video-element']}
						src={poster}
						controls={false}
						auto={autoPlay}
						loop={loop}
						muted={muted}
						posterSrc={posterSrc}
						testID="trigger-video-modal"
						alt={alt}
					/>
				</>
			) : (
				<Video
					className={styles['video-element']}
					src={videoName}
					controls={controls}
					auto={autoPlay}
					loop={loop}
					muted={muted}
					posterSrc={posterSrc}
					testID="video-element"
					alt={alt}
				/>
			)}

			<div className={isPlaying ? styles.video__modal : styles.video__hidden} id="video-player" role="dialog">
				<div className={styles['video__modal-container']}>
					<button
						className={styles.video__close}
						type="button"
						onClick={handleVideoPlayerControls}
						aria-label={formatMessage('close')}
					>
						<CloseIcon size="SM" className={styles['modal-close-icon']} />
					</button>
					<Video
						className={styles['video-element']}
						src={videoName}
						controls={true}
						auto={false}
						loop={loop}
						muted={false}
						posterSrc={posterSrc}
						testID="video-modal"
						alt={alt}
					/>
				</div>
			</div>
		</div>
	)
}

export default VideoPlayer
