import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

export default function CheckIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" {...getEnhancedIconProps(props)}>
			<path
				d="M1 8L5.65625 12.49C5.66207 12.4956 5.67127 12.4956 5.67708 12.49L15 3.5"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
