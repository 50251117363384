import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import QuickAddToCartButton from '~/components/shared/ProductTile/QuickAddToCartModal/QuickAddToCartButton'
import PlusIconAnimated from '~/components/primitives/icons/animations/PlusIconAnimated'
import { ensureString } from '~/types/strict-null-helpers'
import type { Hotspot } from '~/lib/client-server/cms/modules/hotspot'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { getProductsForHotspots } from '~/lib/client-server/cms/sources/coremedia/shared/hotspots'

import styles from './Hotspots.module.scss'

interface HotspotsProps {
	/** A single Hotspot will have an object containing it's location and product information. The location will specify a distance in the form of a percentage from the `top` and `left` of the image, while the `product` will have the the appropriate data to query the product.  */
	hotspots: Hotspot[]

	/** `isMobile` tells the overlay if it is rendering on desktop or mobile. This is generally used by CoreMedia since there can be two distinct desktop and mobile designs */
	isMobile?: boolean

	/** Passes down the asset/media name so that when a hotspot it is passed into analytics to know where the click originated from */
	analytics?: string
}

/** Hotspots is an overlay component that places interactive hotspots over an image that open the Quick Add To Bag. */
const Hotspots = ({ hotspots, isMobile = false, analytics }: HotspotsProps) => {
	const visibility = isMobile ? styles['dt-hidden'] : styles['mb-hidden']

	const { isQuickAddToCartEnabled } = useFeatureFlags() || {}
	const [activeHotspots, setActiveHotspots] = useState<Hotspot[]>()

	const getHotspotProductInformation = useCallback(async () => {
		const hotspotProducts = await getProductsForHotspots(hotspots)
		if (hotspotProducts) {
			setActiveHotspots(hotspotProducts)
		}
	}, [hotspots])

	useEffect(() => {
		if (isQuickAddToCartEnabled && !activeHotspots) getHotspotProductInformation()
	}, [getHotspotProductInformation, isQuickAddToCartEnabled, activeHotspots])

	if (!activeHotspots?.length) return null

	return (
		<ul className={clsx(styles.hotspots__overlay, visibility)}>
			{activeHotspots.map((hotspot, index) => (
				<li
					key={index} // using index over product id, in theory there could be duplicated products causing a duplicated key
					style={{
						left: `${hotspot.location.left}%`,
						top: `${hotspot.location.top}%`,
					}}
				>
					<QuickAddToCartButton
						productName={hotspot.product.name}
						productId={ensureString(hotspot.product.id)}
						productStyleId={ensureString(hotspot.product.style)}
						defaultColor={hotspot.product?.color}
						icon={<PlusIconAnimated />}
						hotspotAnalytics={analytics}
						source={'hotspots'}
					/>
				</li>
			))}
		</ul>
	)
}
export default Hotspots
