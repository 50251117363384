/* eslint-disable no-underscore-dangle */
import { getUrlFromLinkObject } from '~/lib/cms/legacy/helper'
import { CmModuleImpl } from './module'
import { CmTeaserTargetSchema, type CmTeaserTargetSafe } from './schemas'
import type { CallToAction } from '~/lib/client-server/cms/modules'

export class CmTeaserTargetImpl extends CmModuleImpl {
	private _parsedTeaserTarget: CmTeaserTargetSafe
	constructor(data: unknown, locale?: string) {
		super(data, locale)
		this._parsedTeaserTarget = CmTeaserTargetSchema.parse(this.deref(data))
	}

	toUniversal(): CallToAction {
		return {
			text: this.callToActionText,
			url: this.url,
			isEnabled: this?.callToActionEnabled ? this.callToActionEnabled : undefined,
		}
	}

	get raw() {
		return this._parsedTeaserTarget
	}
	get target() {
		return this.raw.target
	}
	// If the call to action is not enabled - we shouldnt render it.
	get callToActionEnabled() {
		return this.raw.callToActionEnabled
	}
	// This should not be an empty string.
	get callToActionText() {
		return this.raw.callToActionText ?? ''
	}
	// This should return a valid URL or undefined that way if we dont have a link we can just not render it
	get url() {
		return getUrlFromLinkObject(this.raw.target, this.locale ?? 'en-us') ?? ''
	}
}
