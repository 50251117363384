import { useCallback } from 'react'
import type { Optional } from 'types/strict-null-helpers'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useQuickAddToCart } from '~/components/hooks/useQuickAddToCart'
import QuickAddToCartIcon from '~/components/primitives/icons/QuickAddToCartIcon'
import styles from './QuickAddToCartButton.module.scss'
import type { QuickAddToCartData } from '~/lib/types/analytics.interface'

interface QuickAddToCartButtonProps {
	productName?: Optional<string>
	productId: string
	productStyleId: string
	source?: QuickAddToCartData['quick_atb']['source']
	/** Default `color` (from MasterProduct) or `code` (from VariantProduct) */
	defaultColor?: string | undefined
	defaultExtendedSize?: string
	onButtonClick?: () => void // used to override the click action of the button
	/** Allow a different icon to be be used for the QATB */
	icon?: React.ReactNode
	hotspotAnalytics?: string
}

const QuickAddToCartButton = ({
	productName,
	productId,
	productStyleId,
	source,
	defaultColor,
	defaultExtendedSize,
	onButtonClick,
	icon = <QuickAddToCartIcon />,
	hotspotAnalytics,
}: QuickAddToCartButtonProps) => {
	const formatMessage = useFormatMessage()
	const { openModal, setIsModal } = useQuickAddToCart()
	const { analyticsManager } = useAnalytics()
	const handleOpenClick = useCallback(() => {
		if (onButtonClick) {
			// This is needed to allow control over a different modal
			onButtonClick()
		} else {
			// ensure that it is always set as a modal on button click since that is the default action
			setIsModal(true)
		}
		analyticsManager.trackQATBButtonClick(
			{
				product_name: productName || '',
				product_style: productStyleId,
				product_id: productId,
			},
			source,
		)

		if (hotspotAnalytics) {
			analyticsManager.trackHotspotClick(hotspotAnalytics)
		}
		openModal({ productId: productStyleId, defaultColor, defaultExtendedSize })
	}, [
		analyticsManager,
		onButtonClick,
		productName,
		productId,
		productStyleId,
		source,
		openModal,
		defaultColor,
		defaultExtendedSize,
		setIsModal,
		hotspotAnalytics,
	])

	return (
		<button
			type="button"
			aria-label={formatMessage('quick-add-to-bag')}
			className={`${styles['quick-add-button']}`}
			onClick={handleOpenClick}
		>
			{icon}
		</button>
	)
}

export default QuickAddToCartButton
