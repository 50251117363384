/* eslint-disable no-underscore-dangle */
import { CmModuleImpl, type DeviceType } from './module'
import {
	CmTeaserSchema,
	type CmTeaserSafe,
	type CmMediaModuleSafe,
	type CmTextTreeSafe,
	type CmFeaturedProductsOverlaySafe,
	type CmTeaserOverlaySafe,
	type CmUaModuleSettingsSafe,
} from './schemas'
import type { ModuleTheme, TextStyle } from '~/lib/client-server/cms/modules'
import { CmTeaserTargetImpl } from './target'
import { isLinkedSetting, isTextStyle } from '~/lib/cms/legacy/type-guard'

/**
 * The CoreMedia teaser is really the basis of most content in CoreMedia.  Most other
 * object types are forms of the Teaser.  That is not ideal but it is what we have today.
 * In some cases, in code, we will represent an object that is a teaser as something else
 * only for more readability.  For example, the media object is actually a teaser but it
 * shouldn't really be.
 */
export class CmTeaserImpl extends CmModuleImpl {
	private _parsedTeaser: CmTeaserSafe
	private _teaserTargets: CmTeaserTargetImpl[]

	constructor(data: unknown, locale?: string) {
		super(data)
		this._parsedTeaser = CmTeaserSchema.parse(this.deref(data))
		this._teaserTargets = this._parsedTeaser.teaserTargets.map((target) => new CmTeaserTargetImpl(target, locale))
	}

	protected get raw() {
		return this._parsedTeaser
	}

	public getViewtype(): string | undefined {
		return this._parsedTeaser.viewtype ?? undefined
	}

	public getModuleId(): string {
		return this._parsedTeaser.id
	}

	public getModuleName(): string | undefined {
		return this._parsedTeaser.name
	}

	public getSnipeText(): string | undefined {
		return this._parsedTeaser.snipeText
	}

	public getHeader(): CmTextTreeSafe | undefined {
		return this._parsedTeaser?.teaserTextAsTree ?? undefined
	}

	public getSubheader(): CmTextTreeSafe | undefined {
		return this._parsedTeaser?.subHeadlineTextAsTree ?? undefined
	}

	/**
	 * Gets the `uaMedia` or `uaMobileMedia` object based on the `deviceType`
	 * @param deviceType
	 * @returns CoreMediaMediaModule[]
	 */
	public getMedia(deviceType: DeviceType): CmMediaModuleSafe[] {
		return deviceType === 'desktop' ? this._parsedTeaser.uaMedia : this._parsedTeaser.uaMobileMedia
	}

	/**
	 * Various Settings From Teaser
	 */

	public getUaModuleSettings(): CmUaModuleSettingsSafe | undefined {
		return this._parsedTeaser.uaModuleSettings
	}

	public getTextStyle(deviceType: DeviceType) {
		//  TODO: Figure out if this should always be defined - it might be a bug on the CM side of things.
		const settingLookupKey =
			deviceType === 'desktop'
				? this.getUaModuleSettings()?.desktopLinkedStyles
				: this.getUaModuleSettings()?.mobileLinkedStyles

		const setting =
			settingLookupKey && this._parsedTeaser.uaModuleLinkSettings.find((obj) => obj && obj.key === settingLookupKey)
		if (!setting || !setting.value) return null

		const classCheck = setting.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
		return classCheck && isLinkedSetting(classCheck) && classCheck.description ? classCheck.description : null
	}

	public getModuleTheme(): ModuleTheme {
		// The return from CoreMedia is a little confusing. The guide say "Light over Dark" but returns 'light-text' designating a dark theme. Setting the default return condition to light as the majority of our content follows this
		const desktopStyle = this.getTextStyle('desktop') === 'light-text' ? 'dark' : null
		const mobileStyle = this.getTextStyle('mobile') === 'light-text' ? 'dark' : null

		// Default style
		let theme: ModuleTheme = 'light'

		if (mobileStyle === 'dark' && desktopStyle === 'dark') {
			// Complete dark theme.
			theme = 'dark'
		} else if (desktopStyle && !mobileStyle) {
			// Dark on desktop only.
			theme = 'dark-desktop'
		} else if (!desktopStyle && mobileStyle) {
			// Dark on mobile only.
			theme = 'dark-mobile'
		}

		return theme
	}

	public isTextCentered() {
		return this.raw.uaModuleSettings?.teaserOverlay?.centerCopy
	}

	public getWidth() {
		return this.raw.uaModuleSettings?.teaserOverlay?.width
	}

	public isTextLinks() {
		return this.raw.uaModuleSettings?.teaserOverlay?.useTextLinks
	}

	public titleStyle(): TextStyle {
		// should NOT type cast but working quickly
		return isTextStyle(this.raw.uaModuleSettings?.headlineStyle) ? this.raw.uaModuleSettings.headlineStyle : 'headline1'
	}
	public descriptionStyle() {
		// should NOT type cast but working quickly
		return isTextStyle(this.raw.uaModuleSettings?.subheadlineStyle)
			? this.raw.uaModuleSettings.subheadlineStyle
			: undefined
	}

	/**
	 * Targets are destinations that the teaser can link to.  Often, these
	 * manifest themselves as buttons on the teaser or simply links that encompass
	 * the entire teaser.
	 * @returns CoreMediaTeaserTarget[]
	 */
	public getTeaserTargets(): CmTeaserTargetImpl[] {
		return this._teaserTargets
	}

	public getTeaserOverlay(deviceType: DeviceType): CmTeaserOverlaySafe | undefined {
		return deviceType === 'desktop'
			? this.getUaModuleSettings()?.teaserOverlay
			: this.getUaModuleSettings()?.mobileTeaserOverlay
	}

	public getFeaturedProductOverlay(deviceType: DeviceType): CmFeaturedProductsOverlaySafe | undefined {
		return deviceType === 'desktop'
			? this.getUaModuleSettings()?.featuredProductsOverlay?.desktop
			: this.getUaModuleSettings()?.featuredProductsOverlay?.mobile
	}

	// textbox position for callout - hero overlay only
	public getPosX() {
		const posX = this._parsedTeaser.uaModuleSettings.teaserOverlay?.positionX ?? 0
		// textbox / content positioning is on desktop only, mobile only has content below the photo
		// x axis - left/right positioning
		if (posX > 0) {
			return 'end'
		}
		if (posX < 0) {
			return 'start'
		}
		return 'center'
	}

	public getPosY() {
		const posY = this._parsedTeaser.uaModuleSettings.teaserOverlay?.positionY ?? 0
		// textbox / content positioning is on desktop only, mobile only has content below the photo
		// y axis - up/down positioning
		if (posY > 0) {
			return 'end'
		}
		if (posY < 0) {
			return 'start'
		}
		return 'center'
	}

	public directionStyle() {
		return this.raw.uaModuleSettings?.desktopDirection
	}
}
