import { useEffect, useRef } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { SCENE7_BASEURL } from '~/lib/constants'

const Video = ({ className, src, loop, auto, muted, posterSrc, controls, testID, alt }) => {
	const formatMessage = useFormatMessage()
	const videoRef = useRef<HTMLVideoElement>(null)

	const handleFocusVideo = () => {
		const video = videoRef.current
		video?.focus({ preventScroll: true })
	}

	const handleBlurVideo = () => {
		const video = videoRef.current
		video?.blur()
	}

	useEffect(() => {
		const video = videoRef.current

		const handleKeyDown = (event) => {
			if (video && document.activeElement === video) {
				switch (event.key) {
					case 'ArrowLeft':
						video.currentTime -= 1
						break
					case 'ArrowRight':
						video.currentTime += 1
						break
					default:
						break
				}
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<video
			data-testid={testID}
			className={className}
			controls={controls}
			loop={loop}
			preload="auto"
			muted={muted}
			ref={videoRef}
			poster={posterSrc}
			autoPlay={auto}
			playsInline
			onMouseEnter={handleFocusVideo}
			onMouseLeave={handleBlurVideo}
			aria-describedby="video-description"
		>
			<source src={`${SCENE7_BASEURL}content/Underarmour/${src}`} />
			<noscript>
				<p>
					{formatMessage('video-error-msg')}
					<a href={`${SCENE7_BASEURL}content/Underarmour/${src}`}>{formatMessage('link-to-video')}</a>{' '}
					{formatMessage('instead')}.
				</p>
				<div id="video-description" hidden>
					<p>{alt}</p>
				</div>
			</noscript>
		</video>
	)
}

export default Video
