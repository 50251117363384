/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { isCoreMediaObject, type CoreMediaObject } from '~/lib/cms/legacy/type-guard'
// import { isString } from '~/lib/utils'
import { createClientLogger } from '~/lib/logger'

export const logger = createClientLogger('coremedia-transform')

const isRecord = (x: unknown): x is Record<string, unknown> => typeof x === 'object' && x !== null

export type DeviceType = 'desktop' | 'mobile'
export abstract class CmModuleImpl {
	private _data: CoreMediaObject
	private _locale: string | undefined

	constructor(data: unknown, locale?: string) {
		if (!isCoreMediaObject(data)) {
			throw new Error('Cannot create CoreMediaModule from non-coremedia data')
		}
		this._data = data
		this._locale = locale
	}

	private findReference(key: string, tree?: Record<string, unknown>): unknown | undefined {
		if (!tree) {
			// Start from root if no tree is given
			// eslint-disable-next-line no-param-reassign
			tree = this._data
		}

		for (const [k, v] of Object.entries(tree)) {
			if (k === key) {
				return v
			}

			if (isRecord(v)) {
				return this.findReference(key, v)
			}
		}

		return undefined
	}

	/**
	 * CoreMedia allows for values to reference a shared set of data.  This function
	 * dereferences those values and returns the actual value.  And it can do it recursively.
	 * @param value
	 * @returns
	 */
	protected deref(value: unknown): unknown {
		// Disableing this. It keeps returning `undefined` in the `uaModuleLinkSettings-> { key: '' }`. It is failing to
		// take the key value from the `uaModuleSettings` and find the "key" in the linked settings and return the value
		// object from that. Here is a screenshot of what it's doing https://cln.sh/vljMtrd4

		// if (isString(value) && value.startsWith('path:/')) {
		// 	return this.findReference(value)
		// }

		let newVal = value
		if (isRecord(newVal)) {
			// For objects, we dererence all values recursively
			for (const [k, v] of Object.entries(newVal)) {
				newVal[k] = this.deref(v)
			}
		}

		if (Array.isArray(newVal)) {
			// For arrays, we dereference all values in the array
			newVal = newVal.map((v) => this.deref(v))
		}

		return newVal
	}

	public get locale() {
		return this._locale
	}
}
