'use client'

import clsx from 'clsx'
import { useCallback, useState } from 'react'
import type { FeaturedProducts as FeaturedProductsProps } from '~/lib/client-server/cms/modules/feature-products'

import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import AnimatedIconSlideout from '~/components/primitives/Chips/AnimatedIconSlideout'
import HangerIcon from '~/components/primitives/icons/HangerIcon'

import styles from './FeaturedProducts.module.scss'
import dynamic from 'next/dynamic'

const FeaturedProductsDialog = dynamic(
	() => import('~/components/cms/legacy/FeaturedProductsDialog/FeaturedProductDialog'),
	{ ssr: false },
)

/** Featured Products is a component used to show featured products in an image. It is a floating icon that can be placed in any of the four corners of the image and will trigger a modal of the items featured */
const FeaturedProducts = ({ theme, products, mobilePosition, desktopPosition }: FeaturedProductsProps) => {
	const formatMessage = useFormatMessage()
	const { analyticsManager } = useAnalytics()
	const [showDialog, setShowDialog] = useState(false)

	const [hasCarouselProducts, setHasCarouselProducts] = useState(false)
	const handleHasCarouselProducts = (state: boolean) => setHasCarouselProducts(state)

	const handleFeaturedProductsClick = useCallback(() => {
		analyticsManager.trackFeaturedProductButtonClick()
		setShowDialog(true)
	}, [analyticsManager])

	const wrapperClasses = clsx('featured-products', styles.featured__products, {
		[styles[`mb-${mobilePosition}`]]: mobilePosition,
		[styles[`dt-${desktopPosition}`]]: desktopPosition,
		[styles.visible]: !!hasCarouselProducts,
	})

	const handleDialogClose = useCallback(() => setShowDialog(false), [])

	return (
		<>
			<div className={wrapperClasses} data-theme-cms={theme}>
				<button type="button" className={styles.btn__add} onClick={handleFeaturedProductsClick}>
					<AnimatedIconSlideout label={formatMessage('shop')} icon={<HangerIcon size="XL" />} />
				</button>
			</div>
			{products && (
				<FeaturedProductsDialog
					featuredProducts={products}
					show={showDialog}
					onClose={handleDialogClose}
					handleHasCarouselProducts={handleHasCarouselProducts}
				/>
			)}
		</>
	)
}
export default FeaturedProducts
