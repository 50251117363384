import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

const CloseIcon = memo(function AlertIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 16 16" fill="none" {...getEnhancedIconProps(props)} xmlns="http://www.w3.org/2000/svg">
			<path d="M1 1L15 15" stroke="currentColor" strokeLinecap="round" />
			<path d="M1 15L15 1" stroke="currentColor" strokeLinecap="round" />
		</svg>
	)
})

export default CloseIcon
