'use client'

import { createContext, useContext } from 'react'
import { noop } from '~/lib/utils'

export interface QuickAddToCartModalParamsInterface {
	productId: string
	/** Default `color` (from MasterProduct) or `code` (from VariantProduct) */
	defaultColor?: string | undefined
	defaultExtendedSize?: string | undefined
}

interface QuickAddToCartContextInterface {
	modalParams: QuickAddToCartModalParamsInterface
	openModal(modal: QuickAddToCartModalParamsInterface): void
	modalActive: boolean
	setModalActive(active: boolean): void
	isModal: boolean
	setIsModal(isModal: boolean): void
}

export const QuickAddToCartContext = createContext<QuickAddToCartContextInterface>({
	modalParams: { productId: '' },
	modalActive: false,
	setModalActive: () => noop,
	isModal: false,
	setIsModal: () => noop,
	openModal: () => noop,
})

export function useQuickAddToCart() {
	return useContext(QuickAddToCartContext)
}
