import { ensureString } from 'types/strict-null-helpers'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { getModuleSetting } from '~/lib/cms/legacy/helper'
import ElementTree from '~/components/shared/ElementTree'
import x from './Headline.module.scss'

interface Props {
	data: CmTeasable
	className?: string
	locale: string
}

const Headline = ({ data, className, locale }: Props) => {
	const content = data.teaserTitle
		? data.teaserTitle
		: data.teaserTextAsTree && <ElementTree element={data.teaserTextAsTree} locale={locale} />
	const defaultHeadlineStyle = getModuleSetting(ensureString(data.viewtype), 'headlineStyle') || 'headline1' // get default from module-settings map or set to headline1
	const userDefinedHeadlineStyle = data?.uaModuleSettings?.headlineStyle ?? defaultHeadlineStyle // if user defined, if not set default headline

	const headlineStyle = `headline ${
		userDefinedHeadlineStyle === 'default' ? defaultHeadlineStyle : userDefinedHeadlineStyle
	}`

	const style = className ?? headlineStyle
	return content ? <div className={`${x.headline} ${style}`}>{content}</div> : <></>
}

export default Headline
