import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import MediaContent from '../partials/MediaContent'
import Headline from '../partials/Headline'
import Subheadline from '../partials/Subheadline'
import styles from './Enrollment.module.scss'
import SnipeText from '../partials/SnipeText'

interface Props {
	data: CmTeasable
	placement?: string
	locale: string
}

const Enrollment = ({ data, locale }: Props) => {
	return (
		<section className={styles.enrollment}>
			<MediaContent data={data} locale={locale} className={styles.enrollment__img} />
			{data?.snipeText && <SnipeText data={data.snipeText} className={styles.enrollment__snipe} />}
			<Headline data={data} className={styles.enrollment__header} locale={locale} />
			<Subheadline data={data} className={styles.enrollment__subheader} locale={locale} />
		</section>
	)
}

export default Enrollment
