import { useMemo } from 'react'
import LazyLoad from 'react-lazy-load'
import Image from '~/components/primitives/Image'
import VideoPlayer from '~/components/shared/VideoPlayer/VideoPlayer'
import type { CmMedia, CmTeasable, UaMedia } from '~/graphql/generated/coremedia/type-document-node'
import mediaQueries from '~/lib/client-only/media-queries'
import { hasFeaturedProductShown } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'
import {
	isCmPicture,
	isCmVideo,
	isLinkedVideo,
	isUaMedia,
	isUaMediaPicture,
	isUaMediaVideo,
} from '~/lib/cms/legacy/type-guard'
import { getImageDimensions } from '~/lib/image'
import logger from '~/lib/logger'
import { getScene7Recipe, scene7RecipeBuilder } from '~/lib/scene7-recipes'
import { ensureString } from '~/types/strict-null-helpers'
import CMLink from './CMLink'
import styles from './MediaItem.module.scss'
import { CmMediaImpl } from '~/lib/client-server/cms/sources/coremedia/shared/media'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'

interface MediaItemProps {
	data: CmTeasable
	mediaPosition?: number
	children?: unknown
	priority?: boolean
	placement?: string
	module?: string
	locale: string
	imageRecipeMobile?: string | null
	imageRecipeDesktop?: string | null
}

const buildMediaItem = (
	media: CmMedia | UaMedia,
	priority: boolean,
	isDesktop: boolean,
	imageRecipe?: string | null,
) => {
	/**
	 * `externalAssetId` and `uaExternalAssetId` are a custom data field that puts the Scene7 asset ID in a singular place to look
	 * this works for both image and video.
	 */
	const mediaAssetId = isUaMedia(media) ? media.externalAssetId : media.uaExternalAssetId

	// UAMedia destinguishes image or video types in a different location than the standard CoreMedia types. `UAMedia` uses a field called `mediaType`.
	// Media can have a type of `CMPicture` or `CMVideo`
	const isImageElement = isUaMedia(media) ? isUaMediaPicture(media) : isCmPicture(media) // this can return false / false
	const isVideoElement = isUaMedia(media) ? isUaMediaVideo(media) : isCmVideo(media) // this can return false / false

	const mediaAltText = ensureString(media?.alt || media.name)

	if (isImageElement) {
		const foundRecipe = imageRecipe ? getScene7Recipe(imageRecipe) : null
		const imageDimensions = getImageDimensions(media, isDesktop, foundRecipe)

		if (!mediaAssetId || !imageDimensions) {
			logger.info("Received a mediaType of CMPicture but couldn't find any image")
			return null
		}

		const { height, width } = imageDimensions
		const imageSrc = scene7RecipeBuilder(mediaAssetId, width, height)

		// Source Media looks opposite of desktop resolutions to render (or prevent render) on the inverse. ie: If it's desktop, we dont want mobile to render.
		const sourceMedia = isDesktop ? mediaQueries.tabletDown : mediaQueries.desktopUp
		return (
			<span className={styles['image-container']}>
				<span style={{ paddingTop: `${(height / width) * 100}%` }} />
				<picture>
					<source
						srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
						media={sourceMedia}
					/>
					<Image
						src={imageSrc}
						alt={mediaAltText}
						width={width}
						height={height}
						sizes="(max-width: 1249px) 98vw, 85vw"
						priority={priority}
						fetchPriority={priority ? 'high' : 'low'}
					/>
				</picture>
			</span>
		)
	}

	if (isVideoElement) {
		if (!mediaAssetId) {
			logger.info('Received CMVideo teaser without a valid video source')
			return null
		}

		const hasDefinedControls = isUaMedia(media) ? media.playerSettings : media?.uaModuleSettings?.playerSettings

		const autoPlay = !!hasDefinedControls?.autoplay
		const controls = !hasDefinedControls?.hideControls
		const loop = !!hasDefinedControls?.loop
		const muted = !!hasDefinedControls?.muted

		// Logic check to see if this is a legacy media item to look for a linked video. This was used to use a video as the poster for another video
		const ensureLegacyCmVideo = !isUaMedia(media) && isCmVideo(media) ? media : undefined

		const targetVideo = ensureLegacyCmVideo?.uaModuleSettings?.targetVideo
		const targetVideoData = ensureLegacyCmVideo?.uaModuleLinkSettings?.find((obj) => obj && obj.key === targetVideo)
		const linkedVideo = targetVideoData?.value?.find((obj) => obj && isLinkedVideo(obj))
		const linkedVideoName = linkedVideo && isLinkedVideo(linkedVideo) ? linkedVideo?.uaExternalAssetId : null

		const videoSrc = linkedVideoName ?? mediaAssetId // chooses the linked video over the primary asset's id.
		const posterMedia = isDesktop ? mediaQueries.desktopUp : mediaQueries.tabletDown

		const videoPlayer = (
			<VideoPlayer
				autoPlay={autoPlay}
				controls={controls}
				loop={loop}
				muted={muted}
				videoName={videoSrc}
				poster={mediaAssetId}
				posterMedia={posterMedia}
				linkedVideo={linkedVideo}
				alt={mediaAltText}
			/>
		)
		return priority ? videoPlayer : <LazyLoad>{videoPlayer}</LazyLoad>
	}

	return null
}

const getTypeFromMedia = (media: CmMedia | UaMedia) => {
	return isUaMedia(media) ? media.mediaType : media.type
}

const MediaItem = ({
	data,
	mediaPosition = 0,
	children,
	priority,
	placement,
	module,
	locale,
	imageRecipeDesktop,
	imageRecipeMobile,
}: MediaItemProps) => {
	/**
	 * CoreMedia started as desktop only, and mobile was added after.
	 * General logic for the media is that `media` (desktop) and `mobileMedia` (mobile) are used to generate unique images for desktop and mobile
	 * If no `mobileMedia` is there, it falls back to `media`.
	 *
	 * As of ~11/15/23 a new `uaMedia` and `uaMobileMedia` was introduced. This logic has been added to maintain support for the native CoreMedia
	 * fields, but to prefer the new fields over the old.
	 */
	const mediaToUseForDesktop = data?.uaMedia && data.uaMedia.length > 0 ? data.uaMedia : data?.media
	const mediaToUseForMobile =
		data?.uaMobileMedia && data.uaMobileMedia.length > 0 ? data.uaMobileMedia : mediaToUseForDesktop

	const desktopMedia = useMemo(
		() => (mediaToUseForDesktop && mediaToUseForDesktop?.[mediaPosition]) || null,
		[mediaPosition, mediaToUseForDesktop],
	)
	const mobileMedia = useMemo(
		() =>
			mediaToUseForMobile && mediaToUseForMobile?.[mediaPosition] ? mediaToUseForMobile[mediaPosition] : desktopMedia,
		[desktopMedia, mediaPosition, mediaToUseForMobile],
	)

	const desktopMediaType = (desktopMedia && getTypeFromMedia(desktopMedia)) || undefined
	const mobileMediaType = (mobileMedia && getTypeFromMedia(mobileMedia)) || undefined

	const { isMobile } = useLayoutMeasurement()
	const mediaType = isMobile ? mobileMediaType : desktopMediaType
	const teaserTarget = data?.teaserTargets?.[mediaPosition]?.target ?? null
	const targetLink =
		teaserTarget && data?.teaserTargets?.length === 1 ? data?.teaserTargets?.[mediaPosition] : undefined
	/**
	 * Disable the link on the image if:
	 * - it has a featured product icon
	 * - it has hotspots
	 */
	const mobileMediaItem = data?.uaMobileMedia?.[mediaPosition]
	const desktopMediaItem = data?.uaMedia?.[mediaPosition]

	// Convert UaMedia Item to universal format
	const mobileImage = mobileMediaItem && new CmMediaImpl(mobileMediaItem)
	const desktopImage = desktopMediaItem && new CmMediaImpl(desktopMediaItem)

	const disableLink = hasFeaturedProductShown(data) || !!mobileImage?.hotspots || !!desktopImage?.hotspots

	const urlLink = disableLink ? undefined : targetLink

	const mediaItemContent = useMemo(
		() => (
			<>
				{mobileMedia && (
					<div className={`module__dt-hide`}>{buildMediaItem(mobileMedia, !!priority, false, imageRecipeMobile)}</div>
				)}

				{desktopMedia && (
					<div className={`module__mb-hide`}>{buildMediaItem(desktopMedia, !!priority, true, imageRecipeDesktop)}</div>
				)}

				{children}
			</>
		),
		[mobileMedia, desktopMedia, imageRecipeMobile, imageRecipeDesktop, priority, children],
	)

	return mediaType === 'CMVideo' || !urlLink ? (
		mediaItemContent
	) : (
		<CMLink
			parent={data}
			link={urlLink}
			locale={locale}
			placement={ensureString(placement)}
			module={ensureString(module)}
			style={{ position: 'relative', display: 'block' }}
		>
			{mediaItemContent}
		</CMLink>
	)
}

export default MediaItem
