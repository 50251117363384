/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { CmModuleImpl } from './module'
import { CmMediaSchema, type CmMediaModuleSafe, type CmHotzoneSafe } from './schemas'
import { scene7RecipeBuilder } from '~/lib/scene7-recipes'
import { CmHotspotImpl } from './hotspots'
import { bestGuessImageDimensions } from '~/lib/image'
import type { ImageReference, MediaType, VideoReference } from '~/lib/client-server/cms/modules'
import { SCENE7_BASEURL } from '~/lib/constants'

export class CmMediaImpl extends CmModuleImpl {
	private _parsedMedia: CmMediaModuleSafe
	private _hotspots?: CmHotspotImpl[]
	private _mediaType?: string

	constructor(data: unknown) {
		super(data)
		this._parsedMedia = CmMediaSchema.parse(this.deref(data))
		this._mediaType = this._parsedMedia?.mediaType || undefined
		this._hotspots =
			this._parsedMedia.hotSpots?.filter((h): h is CmHotzoneSafe => !!h).map((h) => new CmHotspotImpl(h, this)) ||
			undefined
	}

	protected get raw() {
		return this._parsedMedia
	}

	get height() {
		return this.raw.height ?? 0
	}

	get width() {
		return this.raw.width ?? 0
	}

	get alt() {
		return this.raw.alt ?? this.id ?? ''
	}

	get id() {
		return this.raw.externalAssetId ?? this.name
	}

	get name() {
		return this.raw.name ?? undefined
	}

	get src() {
		return this.getImageUrl()
	}

	get mediaType(): MediaType {
		return this._mediaType === 'CMVideo' ? 'video' : 'image'
	}

	get duration() {
		return this.raw.duration || undefined
	}

	private getImageUrl() {
		if (!this.id) return undefined
		if (!this.height || !this.width) {
			const { width, height } = bestGuessImageDimensions(this.id, true)
			return scene7RecipeBuilder(this.id, width, height)
		}
		return scene7RecipeBuilder(this.id, this.width, this.height)
	}

	private getVideoUrl() {
		if (!this.id) return undefined
		return `${SCENE7_BASEURL}content/Underarmour/${this.id}`
	}

	get hotspots() {
		return this._hotspots
	}

	toImage(): ImageReference {
		return {
			id: this.id ?? this.name,
			src: this.getImageUrl(),
			alt: this.alt ?? this.id ?? '',
			height: this.height ?? 0,
			width: this.width ?? 0,
		}
	}

	toVideo(): VideoReference {
		return {
			id: this.id ?? this.name,
			src: this.getVideoUrl(),
			alt: this.alt ?? this.id ?? '',
			duration: this.duration,
		}
	}
}
