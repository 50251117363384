import { z } from 'zod'

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type Literal = z.infer<typeof literalSchema>
type Json = Literal | { [key: string]: Json } | Json[]
const CmJsonSchema: z.ZodType<Json> = z.lazy(() =>
	z.union([literalSchema, z.array(CmJsonSchema), z.record(CmJsonSchema)]),
)

export const CmObjectSchema = z.object({
	creationDate: z.string(),
	id: z.string(),
	modificationDate: z.string(),
	name: z.string(),
	repositoryPath: z.string(),
	type: z.string(),
	uuid: z.string(),
})
export const CmContentSchema = CmObjectSchema

export const CMvaValueSchema = z.object({
	boolean: z.boolean().optional(),
	content: CmContentSchema.optional(),
	float: z.number().optional(),
	int: z.number().optional(),
	list: z.array(z.unknown()).optional(),
	map: z.any().optional(), // Self-referencing...
	string: z.string().optional(),
})

export const CmTextTreeElementSchema = z.object({
	name: z.string().optional(),
	attributes: z.array(z.unknown()),
	children: z.array(
		z.lazy(() =>
			z.union([
				CmTextTreeElementSchema,
				z.object({
					data: z.string().optional(),
				}),
			]),
		),
	),
})

export const CmLinkSchema = z.object({
	id: z.string().optional(),
	type: z.string().optional(),
})

const CmFeaturedProductsOverlaySchema = z.object({
	enabled: z.boolean().optional(),
	positionX: z.number().optional(),
	positionY: z.number().optional(),
	linkedStyles: z.string().optional(),
})

export const CmTeaserOverlaySchema = z.object({
	width: z.number().optional(),
	positionX: z.number(),
	positionY: z.number(),
	useTextLinks: z.boolean().optional(),
	centerCopy: z.boolean().optional(),
	twoColumn: z.boolean().optional(),
})

// NOTE: CmLocalized is a recursive type see https://github.com/colinhacks/zod#recursive-types
//  for more on how we can handle this in zod.
interface CmLocalizedSafe {
	cmvaFetch?: z.infer<typeof CMvaValueSchema>
	creationDate: string
	id: string
	ignoreUpdates?: number
	locale?: string
	localizationRoot: CmLocalizedSafe
	localizedVariant?: CmLocalizedSafe
	localizedVariants: Array<CmLocalizedSafe>
	master: Array<CmLocalizedSafe>
	modificationDate: string
	name: string
	repositoryPath: string
	type: string
	uuid: string
}

export const CmLocalizedSchema: z.ZodType<CmLocalizedSafe> = z.lazy(() =>
	z.object({
		cmvaFetch: CMvaValueSchema.optional(),
		creationDate: z.string(),
		id: z.string(),
		ignoreUpdates: z.number().optional(),
		locale: z.string().optional(),
		localizationRoot: CmLocalizedSchema,
		localizedVariant: CmLocalizedSchema.optional(),
		localizedVariants: z.array(CmLocalizedSchema),
		master: z.array(CmLocalizedSchema),
		modificationDate: z.string(),
		name: z.string(),
		repositoryPath: z.string(),
		type: z.string(),
		uuid: z.string(),
	}),
)

const CmUaModuleSettingsSchema = z.object({
	teaserOverlay: CmTeaserOverlaySchema.optional(),
	mobileTeaserOverlay: CmTeaserOverlaySchema.optional(),
	headlineStyle: z.string().optional(),
	desktopLinkedStyles: z.string().optional(),
	mobileLinkedStyles: z.string().optional(),
	featuredProductsOverlay: z
		.object({
			mobile: CmFeaturedProductsOverlaySchema.optional(),
			desktop: CmFeaturedProductsOverlaySchema.optional(),
		})
		.optional(),
	mobileFeaturedProductsOverlay: CmFeaturedProductsOverlaySchema.optional(),
	externalAssetId: z.string().optional(),
	maxDisplayedSlides: z.number().optional(),
	subheadlineStyle: z.string().optional(),
	numberSlides: z.boolean().optional(),
	linkedIcon: z.string().optional(),
	useSFCCProductTile: z.boolean().optional(),
	mobileViewtype: z.any().optional(),
	viewtypeCategory: z.string().optional(),
	desktopDirection: z.boolean().optional(),
})

export const CmBaseSchema = z.object({
	type: z.string(),
	name: z.string().optional(),
	id: z.string(),
	viewtype: z.string().nullable().optional(),
	uaModuleSettings: CmUaModuleSettingsSchema,
	uaModuleLinkSettings: z.any(),
})

const CmMobileViewTypeSchema = z.object({
	name: z.string(),
	imageRecipes: z.array(z.string()),
	enableTeaserOverlayInlineWrapper: z.boolean(),
	styleClass: z.string(),
	mobileCarousel: z.boolean(),
})

export const CmLinkedContentEntrySchema = z.object({
	key: z.string(),
	value: z.array(CmContentSchema).optional(),
})

export const CmLinkedContentEntryPaginationResultSchema = z.object({
	result: z.array(CmLinkedContentEntrySchema).optional(),
	totalCount: z.number().optional(),
})

export const CmLinkedContentSchema = z.object({
	id: z.string().optional(),
	type: z.string().optional(),
	productRef: z
		.object({
			externalId: z.string().optional(),
		})
		.optional(),
})

const CmUaSettingsSchema = z.object({
	style: z.string().nullable(),
	c_color: z.string().nullable(),
})

const CmTargetSchema = z.discriminatedUnion('type', [
	z.object({
		type: z.literal('CMExternalLink'),
		id: z.string().optional(),
		url: z.string().optional(),
	}),
	z.object({
		type: z.literal('CMExternalChannel'),
		id: z.string().optional(),
		categoryRef: z.object({
			externalId: z.string(),
		}),
	}),
	z.object({
		type: z.literal('CMExternalPage'),
		id: z.string().optional(),
		externalId: z.string().optional(),
	}),
	z.object({
		type: z.literal('CMExternalProduct'),
		id: z.string().optional(),
		productRef: z.object({
			externalId: z.string(),
		}),
		uaSettings: CmUaSettingsSchema.optional(),
	}),
	z.object({
		type: z.literal('CMProductTeaser'),
		id: z.string().optional(),
		productRef: z.object({
			externalId: z.string(),
		}),
		uaSettings: CmUaSettingsSchema.optional(),
	}),
	z.object({
		type: z.literal('UABucket'),
		id: z.string().optional(),
		link: z.object({
			id: z.string(),
		}),
	}),
])

export const CmLinkableContentSchema = CmTargetSchema

export const CmTeaserTargetSchema = z.object({
	type: z.string().optional(),
	target: CmTargetSchema.nullable(),
	callToActionEnabled: z.boolean().nullable().optional(),
	callToActionText: z.string().nullable().optional(),
})

export const CmSettingsSchema = z.object({
	cmvaFetch: CMvaValueSchema.optional(),
	creationDate: z.string().optional(),
	id: z.string().optional(),
	identifier: z.string().optional(),
	ignoreUpdates: z.number().optional(),
	locale: z.string().optional(),
	localizationRoot: CmLocalizedSchema,
	localizedVariant: CmLocalizedSchema.optional(),
	localizedVariants: z.array(CmLocalizedSchema).optional(),
	master: z.array(CmLocalizedSchema),
	modificationDate: z.string(),
	name: z.string(),
	repositoryPath: z.string(),
	/** Retrieves settings as JSON by a list of list with path segments, e.g. settings(path: [["path1-segment1", "path1-segment2"], ["path2-segment1", "path2-segment2"]]. Supports "\*" as a wildcard to retrieve all settings at once: settings(path: "\*") */
	settings: CmJsonSchema.optional(),
	type: z.string(),
	uuid: z.string(),
})

export const CmHotzoneSchema = z.object({
	alt: z.string().optional(),
	centerX: z.number().optional(),
	centerY: z.number().optional(),
	coords: z.string(),
	linkedContent: CmLinkableContentSchema,
	shape: z.string().optional(),
})

export const CmMediaSchema = z.object({
	type: z.string().optional(),
	alt: z.string().nullable().optional(),
	externalAssetId: z.string().nullable().optional(),
	height: z.number().nullable().optional(),
	hotSpots: z.array(CmHotzoneSchema).nullable().optional(),
	mediaType: z.string().nullable().optional(),
	name: z.string().nullable().optional(),
	width: z.number().nullable().optional(),
	duration: z.number().nullable().optional(),
})

export const CmAuthorsSchema = z.object({
	displayName: z.string().optional(),
	uaMedia: z.array(CmMediaSchema).optional(),
})

export const CmProductTeaser = z.object({
	type: z.enum(['CMExternalProduct', 'CMProductTeaser']),
	id: z.string().optional(),
	productRef: z.object({
		externalId: z.string(),
	}),
})

export const CmTeaserSchema = CmBaseSchema.extend({
	uaMedia: z.array(CmMediaSchema),
	uaMobileMedia: z.array(CmMediaSchema),
	snipeText: z.string().optional(),
	subHeadlineTextAsTree: CmTextTreeElementSchema.nullable(),
	teaserTitle: z.string().optional(),
	teaserText: z
		.object({
			text: z.string(),
			textAsTree: CmTextTreeElementSchema,
		})
		.nullable()
		.optional(),
	teaserTextAsTree: CmTextTreeElementSchema.nullable(),
	teaserTargets: z.array(CmTeaserTargetSchema),
	authors: z.array(CmAuthorsSchema).optional(),
	related: z.array(CmProductTeaser).optional(),
})

export const CmFeaturedProductsSchema = CmTeaserSchema

export const CmNavigationSchema = CmTeaserSchema

// Teasable items are of type CMTeasable in CM graphql docs but come back as various types.
// this is a wip
export const CmTeasableItemSchema = z.union([
	z
		.object({
			type: z.literal('CMProductTeaser'),
			id: z.string().optional(),
			productRef: z.object({
				externalId: z.string(),
			}),
			uaSettings: z
				.object({
					style: z.string(),
					c_color: z.string(),
				})
				.optional(),
		})
		.optional(),
	CmTeaserSchema,
	z.any(),
])

// Collections / Buckets have additional schema that the base teaser does not have
export const CmCollectionSchema = CmTeaserSchema.extend({
	teasableItems: z.array(CmTeasableItemSchema),
})

interface CmTaxonomySafe extends z.infer<typeof CmTeaserSchema> {
	children?: Array<CmTaxonomySafe>
	childrenPaged?: CmTaxonomyPaginationResultSafe
	externalReference?: string
	value?: string
	parent?: CmTaxonomySafe
	pathToRoot?: Array<CmTaxonomySafe>
}

interface CmTaxonomyPaginationResultSafe {
	result?: Array<CmTaxonomySafe>
	totalCount?: number
}

export const CmTaxonomySchema: z.ZodType<CmTaxonomySafe> = z.lazy(() =>
	CmTeaserSchema.merge(
		z.object({
			children: z.array(CmTaxonomySchema).optional(),
			childrenPaged: z
				.object({
					result: z.lazy(() => z.array(CmTaxonomySchema).optional()),
					totalCount: z.number().optional(),
				})
				.optional(),
			externalReference: z.string().optional(),
			value: z.string().optional(),
			parent: CmTaxonomySchema.optional(),
			pathToRoot: z.array(CmTaxonomySchema).optional(),
		}),
	),
)

interface CmLocTaxonomyPaginationResultSafe {
	result?: Array<CmLocTaxonomySafe>
	totalCount?: number
}

interface CmLocTaxonomySafe extends CmTaxonomySafe {
	latitudeLongitude?: string
	locChildren?: Array<CmLocTaxonomySafe>
	locChildrenPaged?: CmLocTaxonomyPaginationResultSafe
	postcode?: string
}

export const CmLocTaxonomySchema: z.ZodType<CmLocTaxonomySafe> = z.lazy(() =>
	CmTeaserSchema.merge(
		z.object({
			children: z.array(CmTaxonomySchema).optional(),
			childrenPaged: z
				.object({
					result: z.lazy(() => z.array(CmTaxonomySchema).optional()),
					totalCount: z.number().optional(),
				})
				.optional(),
			externalReference: z.string().optional(),
			value: z.string().optional(),
			parent: CmTaxonomySchema.optional(),
			pathToRoot: z.array(CmTaxonomySchema).optional(),
			latitudeLongitude: z.string().optional(),
			locChildren: z.array(CmLocTaxonomySchema).optional(),
			locChildrenPaged: z
				.object({
					result: z.array(CmLocTaxonomySchema).optional(),
					totalCount: z.number().optional(),
				})
				.optional(),
			postcode: z.string().optional(),
		}),
	),
)

export const CmLinkableSchema = z.object({
	cmvaFetch: CMvaValueSchema.optional(),
	contentInSetting: z.array(CmLinkedContentEntrySchema).optional(),
	contentInSettingPaged: CmLinkedContentEntryPaginationResultSchema.optional(),
	contentInStruct: z.array(CmLinkedContentEntrySchema).optional(),
	contentInStructPaged: CmLinkedContentEntryPaginationResultSchema.optional(),
	context: CmNavigationSchema.optional(),
	creationDate: z.string().optional(),
	extDisplayedDate: z.string().optional(),
	htmlDescription: z.string().optional(),
	htmlTitle: z.string().optional(),
	id: z.string().optional(),
	ignoreUpdates: z.number().optional(),
	keywords: z.string().optional(),
	keywordsList: z.array(z.string()).optional(),
	link: CmLinkSchema.optional(),
	locale: z.string().optional(),
	localizationRoot: CmLocalizedSchema.optional(),
	localizedVariant: CmLocalizedSchema.optional(),
	localizedVariants: z.array(CmLocalizedSchema).optional(),
	locationTaxonomy: z.array(CmLocTaxonomySchema).optional(),
	locationTaxonomyPaged: z.object({
		result: z.lazy(() => z.array(CmLocTaxonomySchema).optional()),
		totalCount: z.number().optional(),
	}),
	master: z.optional(z.array(CmLocalizedSchema)),
	modificationDate: z.string().optional(),
	name: z.string().optional(),
	navigationPath: z.array(z.any()).optional(), // Self-referencing...
	remoteLink: z.string().optional(),
	repositoryPath: z.string().optional(),
	segment: z.string().optional(),
	/** Retrieves settings as JSON by a list of list with path segments, e.g. settings(path: [["path1-segment1", "path1-segment2"], ["path2-segment1", "path2-segment2"]]. Supports "\*" as a wildcard to retrieve all settings at once: settings(path: "\*") */
	settings: CmJsonSchema.optional(),
	subjectTaxonomy: CmTaxonomySchema.optional(),
	subjectTaxonomyPaged: z.object({
		result: z.lazy(() => z.array(CmTaxonomySchema).optional()),
		totalCount: z.number().optional(),
	}),
	title: z.string().optional(),
	type: z.string(),
	uaContentInSetting: z.array(CmLinkedContentEntrySchema).optional(),

	/**
	 * Retrieves the linked settings documents, optionally filtering for
	 * a specific setting. This supports the situation where multiple values
	 * are defined in separate settings document - in this all the values
	 * are required, not just the first one found.
	 */
	uaLinkedSettings: z.array(CmSettingsSchema).optional(),
	uaMobileLayoutSettings: CmJsonSchema.optional(),
	uaModuleLinkSettings: z.array(CmLinkedContentSchema).optional(),
	uaModuleSettings: CmJsonSchema.optional(),

	/**
	 * Version of `settings` also supporting inheritance; if a setting
	 * is not found, the search is continued "up" the navigation tree.
	 * Note that this mechanism will not find inherited settings for
	 * unaugmented categories. However, with the Under Armour custom inheritance
	 * these categories do not inherit placements and there should be no
	 * call for settings on these pages.
	 */
	uaSettings: CmJsonSchema.optional(),
	uuid: z.string().optional(),
	validFrom: z.string().optional(),
	validTo: z.string().optional(),
	viewtype: z.string().optional(),
})

export const CmPersonSchema = CmTeaserSchema.merge(
	z.object({
		firstName: z.string().optional(),
		lastName: z.string().optional(),
		eMail: z.string().optional(),
		jobTitle: z.string().optional(),
	}),
)

export const CmContentEntrySchema = z.object({
	key: z.string().optional(),
	value: z.array(CmObjectSchema).optional(),
})
export const CmUaModuleLinkSettingsSchema = z.array(
	z.lazy(() =>
		z.object({
			key: z.string(),
			value: z.unknown(),
		}),
	),
)

export type CmTextTreeSafe = z.infer<typeof CmTextTreeElementSchema>
export type CmFeaturedProductsOverlaySafe = z.infer<typeof CmFeaturedProductsOverlaySchema>
export type CmTeaserOverlaySafe = z.infer<typeof CmTeaserOverlaySchema>
export type CmMobileViewSafe = z.infer<typeof CmMobileViewTypeSchema>
export type CmTeaserTargetSafe = z.infer<typeof CmTeaserTargetSchema>
export type CmTeasableItemSafe = z.infer<typeof CmTeasableItemSchema>
export type CmMediaModuleSafe = z.infer<typeof CmMediaSchema>
export type CmTeaserSafe = z.infer<typeof CmTeaserSchema>
export type CmCollectionSafe = z.infer<typeof CmCollectionSchema>
export type CmPersonSafe = z.infer<typeof CmPersonSchema>
export type CmHotzoneSafe = z.infer<typeof CmHotzoneSchema>
export type CmUaModuleLinkSettingsSafe = z.infer<typeof CmUaModuleLinkSettingsSchema>
export type CmUaModuleSettingsSafe = z.infer<typeof CmUaModuleSettingsSchema>
export type CmProductTeaserSafe = z.infer<typeof CmProductTeaser>
