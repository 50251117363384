import type { Optional } from 'types/strict-null-helpers'
import { ensureString } from 'types/strict-null-helpers'
import { moduleSettingsMap, type ModuleSettings } from '~/components/cms/legacy/module-settings-map'
import type { CmCollection, Content, LinkedContentEntry, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import { isProductUpc } from '~/lib/products'
import {
	isCmCollection,
	isCmExternalChannel,
	isCmExternalLink,
	isCmExternalPage,
	isCmExternalProduct,
	isCoreMediaLink,
	isCoreMediaPicture,
	isLinkedSetting,
} from './type-guard'
import { getCarouselCount, hasMobileCarousel } from './bucket'
import { getLocaleUrl } from '~/lib/i18n/urls'
import { SCENE7_BASEURL } from '~/lib/constants'
import { createClientLogger } from '~/lib/logger'

const logger = createClientLogger('coremedia')

export interface StandardLink {
	cmsId: string
	type: string
	url: string
}

/**
 * CoreMedia has different ways of referencing links to other content, products or categories
 * We use the type to determine what we should be expecting.  This function will convert a given
 * CM link to the a usable string.
 */
export function getUrlFromLinkObject(link: unknown, locale: string): string | null {
	if (isCoreMediaLink(link)) {
		if (isCmExternalLink(link)) {
			if (!link.url) return null
			return ensureString(link.url)
		}

		// Check if target is a for an external page CTA
		if (isCmExternalPage(link)) {
			if (!link?.externalId) return null
			return getLocaleUrl(`/t/${ensureString(link?.externalId)}`, locale)
		}

		// Check if target is for a category CTA
		if (isCmExternalChannel(link)) {
			if (!link?.categoryRef?.externalId) return null
			return getLocaleUrl(`/products/byid/${ensureString(link?.categoryRef?.externalId)}`, locale)
		}

		// Check if target is for a product CTA
		if (isCmExternalProduct(link)) {
			if (!link?.productRef?.externalId) return null
			return isProductUpc(link.productRef?.externalId)
				? `/product/upc/${link.productRef?.externalId}`
				: `/p/${link.productRef?.externalId}.html`
		}
	}

	return null
}

/**
 * CoreMedia has different ways of referencing links to other content, products or categories
 * We use the type to determine what we should be expecting.  This function will convert a given
 * CM link to the a usable URL.  The refs is actually a list of Content items that we pull from
 * the RichText object's `textReferencedContent` field.  The id is the id of the link we are
 * looking for and is usually pulled from the associated RichText object's `textAsTree` object.
 * @param refs
 * @param id
 * @param locale
 * @returns
 */
export function getUrlFromReferenceObject(refs: Content[], id: string, locale: string): string | null {
	const content = refs.find((ref) => ref.id === id)
	if (!content) {
		logger.warn(`Could not find content URL reference with id ${id}`)
		return null
	}

	if (isCoreMediaLink(content)) {
		return getUrlFromLinkObject(content, locale)
	}

	if (isCoreMediaPicture(content)) {
		return `${SCENE7_BASEURL}image/Underarmour/${content.name}`
	}

	logger.warn(
		`Attempting to get a URL from a reference object but the reference object is not recognized: ${content.type}, ${content.name}, ${content.id}`,
	)
	return null
}

/**
 * Quickly Lookup and create the module text styles based on the `moduleLinkSettings`
 * @param moduleLinkSettings
 * @param path string - settings path, ex: `path:/mobileLinkedSettings`
 * @param isMobile boolean - if mobile, adds 'mb' if false, adds 'dt'
 * @returns string ex `module--text-dark-dt` or ''
 */
/** @deprecated Please use `getLinkedContentSetting` to return the setting name */
export function getModuleLinkSettings(
	moduleLinkSettings: Maybe<Maybe<LinkedContentEntry>[]> | undefined,
	path: Optional<string>,
	isMobile = true,
): string {
	const view = isMobile ? 'mb' : 'dt'
	const setting = path && moduleLinkSettings ? moduleLinkSettings.find((obj) => obj && obj.key === path) : undefined

	if (setting && setting.value) {
		const classCheck = setting.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
		return classCheck && isLinkedSetting(classCheck) ? `module--${classCheck.description}-${view}` : ''
	}
	return ''
}

/**
 * Primarily used for Desktop Settings - pass the `viewtype` and the param to check the settings mape if the setting exists or not.
 * @param viewtype string generally a class setting like `dt-general-banner`
 * @param param string param to search for ex `desktopTeaserOverlay`
 * @returns boolean
 */
/** @deprecated Please use `getSettingsFromModuleMap` to return the setting name */
export function getModuleSettingBool(viewtype: string, param: string): boolean {
	// console.log(`getModuleSettingBool viewtype: ${viewtype} / param:${param}`)
	return moduleSettingsMap[viewtype] && moduleSettingsMap[viewtype][param] ? moduleSettingsMap[viewtype][param] : false
}

/**
 * Primarily used for Desktop Settings - pass the `viewtype` and the param to check the settings mape if the setting exists or not.
 * @param viewtype string generally a class setting like `dt-general-banner`
 * @param param string param to search for ex `desktopTeaserOverlay`
 * @returns
 */
/** @deprecated Please use `getSettingsFromModuleMap` to return the setting name */
export function getModuleSetting(viewtype: string, param: string) {
	// console.log(`getModuleSettingBool viewtype: ${viewtype} / param:${param}`);
	return moduleSettingsMap[viewtype] && moduleSettingsMap[viewtype][param] ? moduleSettingsMap[viewtype][param] : ''
}

/**
 * When using this as a class name, make sure to prepend either `dt-` or `mb-` for the complete style name. Classes like `dt-top_left` or
 * `mb-hidden` are standard classes that generally would not need to be added to the individual module classes unless specific overrides
 * are needed.
 */
export type SimpleOverlayPosition = 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right' | 'hidden'

export type ModuleView = 'mobile' | 'desktop'

export type ModuleTextStyle = 'dark-text' | 'light-text'

/**
 * Gets the settings from the module-settings-map.ts by accepting a `viewtype`.
 * @param viewtype String of text from CoreMedia designating the template of the item. ex: `dt-general-banner`
 * @returns all mapped settings from the module settings map for the supplied view or undefined
 */
export function getSettingsFromModuleMap(viewtype: string): ModuleSettings | undefined {
	return moduleSettingsMap[viewtype] || undefined
}

/**
 * Gets the setting for additionalClass from the module-settings-map.ts by accepting a `viewtype`.
 * @param viewtype String of text from CoreMedia designating the template of the item. ex: `dt-general-banner`
 * @returns string or undefined - string is a class from the mapped settings ex: `module--full-width-hero-mb`
 */
export function getMappedSettingStyles(viewtype: string): string | undefined {
	const moduleSettings = getSettingsFromModuleMap(viewtype)
	return moduleSettings && moduleSettings.additionalClass
}

/**
 * Gets either the mobile or desktop image recipe from the `module-settings-map.ts` by accepting a `viewtype`
 * @param viewtype String of text from CoreMedia designating the template of the item. ex: `dt-general-banner`
 * @returns array of strings, ex: `['recipie-bucket-router']` from the module settings map
 */
export function getMappedSettingImageRecipe(
	viewtype: string,
	view: ModuleView = 'mobile',
): ModuleSettings['mobileImageRecipes'] | ModuleSettings['desktopImageRecipes'] | undefined {
	const mappedSettings = getSettingsFromModuleMap(viewtype)
	return mappedSettings && view === 'mobile' ? mappedSettings?.mobileImageRecipes : mappedSettings?.desktopImageRecipes
}

/**
 * Evaluates `LinkedContentEntry` to find the description. There can be various types of linked content, this is used to look for settings,
 * which live in the `description` field of a `CMSymbol`
 * @param setting
 * @returns
 */
export function getLinkedContentSetting(setting: LinkedContentEntry) {
	if (!setting || !setting.value) return null

	const classCheck = setting.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)

	return classCheck && isLinkedSetting(classCheck) && classCheck.description ? classCheck.description : null
}

/**
 * Evaluate a `CmCollection` object to get `linkedStyles` for a module based on a desktop or mobile view.
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @param view String of type `ModuleTextStyle` - is either `mobile` or `desktop`
 * @returns `dark-text` | `light-text` | undefined - the `dark-text` `light-text` comes from the setting in CoreMedia
 */
export function getModuleTextStyling(data: CmCollection, view: ModuleView = 'mobile'): ModuleTextStyle | undefined {
	const { uaModuleSettings, uaModuleLinkSettings } = data
	if (!uaModuleSettings || !uaModuleLinkSettings) return undefined

	const linkedStyles = view === 'mobile' ? uaModuleSettings?.mobileLinkedStyles : uaModuleSettings?.desktopLinkedStyles
	const setting = linkedStyles && uaModuleLinkSettings.find((obj) => obj && obj.key === linkedStyles)
	return setting && getLinkedContentSetting(setting)
}

/**
 * Evaluates a data object to find the `uaMobileLayoutSettings` if present
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns uaMobileLayoutSettings or undefined - `uaMobileLayoutSettings` are a json return that is not typed
 */
export function getMobileSettings(data: CmCollection): CmCollection['uaMobileLayoutSettings'] | undefined {
	const { uaMobileLayoutSettings } = data
	if (!uaMobileLayoutSettings) return undefined
	return uaMobileLayoutSettings
}

/**
 * Evaluates a data object to find the `styleClass` from `uaMobileLayoutSettings` if present
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns string or undefined - string is a class from CoreMedia ex: `module--full-width-hero-mb`
 */
export function getMobileStyleClass(data: CmCollection): string | undefined {
	const mobileSettings = getMobileSettings(data)
	return (mobileSettings && mobileSettings?.styleClass) || undefined
}

/**
 * Create a string of styles used from the Legacy CM site.
 *
 * Eventually this will be deprecated as we work out old modules and reduce the `styles/partials` folder
 * @param data A single data object from the CoreMedia return, generally a `CmTeaser` or `CmCollection` containing props such as `viewtype`, `teasableItems`, `uaModuleSettings` and more
 * @returns string of classes from legacy CoreMedia styles ex: `module--dark-text-mb module--light-text-dt module--carousel-mb`
 */
export function getLegacyStyles(data: CmCollection): string {
	const classes: string[] = []

	/**
	 * The original CM styles required a return of `dark-text` or `light-text`, so if no setting is found, it defaults to `dark-text`
	 */
	// Get Text Styling for Mobile
	const mbTextStyles = getModuleTextStyling(data, 'mobile') ?? `dark-text`
	if (mbTextStyles) {
		classes.push(`module--${mbTextStyles}-mb`)
	}
	// Get Text Styling for Desktop,
	const dtTextStyles = getModuleTextStyling(data, 'desktop') ?? `dark-text`
	if (dtTextStyles) {
		classes.push(`module--${dtTextStyles}-dt`)
	}

	// if is colletion (bucket/carousel)
	if (isCmCollection(data)) {
		// if its a mobile carousel or not class
		const isMobileCarousel = hasMobileCarousel(data)
		if (isMobileCarousel) {
			classes.push(`module--carousel-mb`)
		}

		const columnCountMobile = getCarouselCount(data, 'mobile')
		if (columnCountMobile) {
			classes.push(`list--columns-mb-${columnCountMobile}`)
		}

		const columnCountDesktop = getCarouselCount(data, 'desktop')
		if (columnCountDesktop) {
			classes.push(`list--columns-dt-${columnCountDesktop}`)
		}

		/**
		 * not sure where these come from. this is used on most of the blocks
		 * but cannot find the logic that creates this
		 */
		classes.push('module--left-mb')
		classes.push('module--left-dt')
	}

	return classes.join(' ')
}

/**
 * Evauluate two numbers to get the simple position of an overlay. Returns a position in one of the four corners of a location, `top_left`,
 * `top_right`, `bottom_left` or `bottom_right`. CoreMedia returns values for positioning between -50 and 50 with 0 being the middle of the image.
 * @param posX
 * @param posY
 * @returns
 */
export function getSimpleOverlayPosition(posX: number, posY: number): SimpleOverlayPosition {
	// make sure we have `positionX` and `positionY` if not, default to `top_left` position
	const vertical = posY && posY > 0 ? 'bottom' : 'top'
	const horizontal = posX && posX > 0 ? 'right' : 'left'
	return `${vertical}_${horizontal}`
}

export function getContentIdFromCmIdentifier(identifier: string): string | undefined {
	if (identifier.startsWith('coremedia:///cap/content')) {
		const split = identifier.split('/')
		return split[split.length - 1]
	}
	return undefined
}

export function getCssColor(data) {
	let mobileCss
	let desktopCss
	const dt = 'desktop'
	const mb = 'mobile'
	const styleMap = data.uaModuleLinkSettings
	const checkCss = (a) =>
		styleMap?.forEach((x) => {
			if (x?.key?.includes(a)) {
				x?.value?.forEach((y) => {
					if (a === dt) {
						desktopCss = y && isLinkedSetting(y) ? `dt-${y.description}` : ''
					} else {
						mobileCss = y && isLinkedSetting(y) ? `mb-${y?.description}` : ''
					}
				})
			}
		})
	checkCss(dt)
	checkCss(mb)
	return {
		mobileCss,
		desktopCss,
	}
}
