import { ensureString } from 'types/strict-null-helpers'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { getModuleSetting } from '~/lib/cms/legacy/helper'
import ElementTree from '~/components/shared/ElementTree'
import x from './Subheadline.module.scss'

interface Props {
	data: CmTeasable
	className?: string
	locale: string
}

const Subheadline = ({ data, className, locale }: Props) => {
	const content = data.subHeadlineTextAsTree ? (
		<ElementTree element={data.subHeadlineTextAsTree} refs={data.subHeadlineTextReferencedContent} locale={locale} />
	) : (
		data.teaserTitle
	)
	const defaultSubheadlineStyle = getModuleSetting(ensureString(data.viewtype), 'subheadlineStyle')
	const userDefinedSubheadlineStyle = data?.uaModuleSettings?.subheadlineStyle ?? defaultSubheadlineStyle // if user defined, if not set default subheadline

	const subheadlineStyle = userDefinedSubheadlineStyle && `subheadline--${userDefinedSubheadlineStyle}`

	/**
	 append cssClass to subheadline style
	 */
	const style = className ? `${className} ${subheadlineStyle}` : subheadlineStyle

	return content ? <div className={`${x.subheadline} ${style}`}>{content}</div> : <></>
}

export default Subheadline
